import React, { useState, useEffect } from "react";
import LoginService from "../../services/LoginService";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Footer from "../../components/footer/Footer";
import * as yup from "yup";
import $ from "jquery";

import { VscEye, VscEyeClosed } from "react-icons/vsc";
import CourseService from "../../services/CourseService";
import Select from "react-select";
import { useNavigate } from "react-router-dom";

export default function Login(props) {
  const schema = yup.object().shape({
    username: yup
      .string()
      .required("Username is required.")
      .test(
        "len",
        "Must be greater than 5 characters",
        (val) => val.length > 5
      ),
    fname: yup
      .string()
      .required("First Name is required.")
      .test(
        "len",
        "Must be greater than 3 characters",
        (val) => val.length > 3
      ),
    mname: yup
      .string()
      .required("Middle Name is required.")
      .test(
        "len",
        "Must be greater than 3 characters",
        (val) => val.length > 3
      ),
    lname: yup
      .string()
      .required("Last Name is required.")
      .test(
        "len",
        "Must be greater than 3 characters",
        (val) => val.length > 3
      ),
    email: yup
      .string()
      .email("Enter Valid Email Id.")
      .required("Email is required."),
    selectedIntrests: yup
      .array()
      .test("is-42", "Minimum one interest should be selected", (value) => {
        return selectedIntrests.length !== 0;
      }),

    password: yup
      .string()
      .required("Password is required.")
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        "Password Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
      ),

    confirmPassword: yup
      .string()
      .required("Confirm password is required.")
      .oneOf(
        [yup.ref("password"), null],
        "Password and Confirm Password must match"
      ),
  });
  // const [temp, setTemp] = useState(true);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [eyes, setEyes] = useState({ password1: false, password2: false });
  const [invalidmsg, setInvalidmsg] = useState({ message: "", success: false });
  const [confirmBox, setConfirmBox] = useState(false);
  const [courceOptions, setCourceOptions] = useState([]);
  const [selectedIntrests, setSelectedIntrests] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    var headerHeight = $(".navbar").height();
    var footerHeight = $(".footer").height();
    var totalHeight = headerHeight + footerHeight;
    $(".login").css("min-height", "calc(99vh - " + totalHeight + "px - 55px");
    const token = localStorage.getItem("token");
    if (token) {
      navigate("/");
    }
    CourseService.getCourses()
      .then((res) => {
        let temp = res.data.map((item) => {
          return { label: item.name, value: item.name };
        });
        setCourceOptions(temp);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  //RM#252355 --start
  const handleClick = () => {
    if (invalidmsg.message) {
      window.scrollTo({
        top: "#InvalidAlert",
        behavior: "smooth",
      });
    }
  }; //--end

  const handleSignup = (data) => {
    data = { ...data, selectedIntrests: selectedIntrests };
    LoginService.postUser(data)
      .then((res) => {
        if (data.email.split("@")[1].startsWith("radix")) {
          setInvalidmsg({
            message: "Signup successfully !!! Check your E-mail for login",
            success: true,
          });
          let message = "Signup successfully !!! Check your E-mail for login";
          setConfirmBox(true);
          navigate("/", message);
        } else {
          setInvalidmsg({
            message: "Signup successfully !!! Wait for approval E-mail",
            success: true,
          });
          let message = "Signup successfully !!! Wait for approval E-mail";
          setConfirmBox(true);
          navigate("/", message);
        }
      })
      .catch((ex) => {
        if (ex.response.data.error) {
          setConfirmBox(true);
          setInvalidmsg({ message: ex.response.data.error, success: false });
        }
      });
    {
      setTimeout(() => setConfirmBox(false), 5000);
    }
  };

  return (
    <>
      <section className="container login my-5">
        <div className="row m-0">
          {confirmBox && (
            <div className="formloade">
              <div className="text-center">
                <div className="col-lg-5 col-md-7 col-sm-10 mx-auto confirmbox">
                  {invalidmsg.message && (
                    <div
                      id="InvalidAlert"
                      className={`alert  ${
                        invalidmsg.success ? "alert-success" : "alert-danger"
                      }`}
                    >
                      {invalidmsg.message}
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
          <div className="col-lg-5 col-md-7 col-sm-10 mx-auto bg-white py-lg-5 py-3 formcard">
            <h2 className="text-center heading font-weight-300">Sign Up!</h2>
            <form
              onSubmit={handleSubmit(handleSignup)}
              className="px-lg-4 py-4"
              method="post"
            >
              <div className="mb-3">
                <label htmlFor="username" className="form-label">
                  Username <sup class="text-danger">*</sup>
                </label>
                <input
                  {...register("username")}
                  className="form-control"
                  placeholder="John doe"
                />
                <small className="form-text d-block my-2 text-red">
                  {errors.username?.message}
                </small>
              </div>
              <div className="mb-3">
                <label htmlFor="fname" className="form-label">
                  First Name <sup class="text-danger">*</sup>
                </label>
                <input
                  {...register("fname")}
                  className="form-control"
                  placeholder="First name"
                />
                <small className="form-text d-block my-2 text-red">
                  {errors.fname?.message}
                </small>
              </div>
              <div className="mb-3">
                <label htmlFor="mname" className="form-label">
                  Middle Name <sup class="text-danger">*</sup>
                </label>
                <input
                  {...register("mname")}
                  className="form-control"
                  placeholder="Middle name"
                />
                <small className="form-text d-block my-2 text-red">
                  {errors.mname?.message}
                </small>
              </div>
              <div className="mb-3">
                <label htmlFor="mname" className="form-label">
                  Last Name <sup class="text-danger">*</sup>
                </label>
                <input
                  {...register("lname")}
                  className="form-control"
                  placeholder="Last name"
                />
                <small className="form-text d-block my-2 text-red">
                  {errors.lname?.message}
                </small>
              </div>
              <div className="mb-3">
                <label htmlFor="email" className="form-label">
                  Email <sup class="text-danger">*</sup>
                </label>
                <input
                  {...register("email")}
                  className="form-control"
                  maxLength={50}
                  placeholder="john.doe@radixweb.com"
                />
                <small className="form-text d-block my-2 text-red">
                  {errors.email?.message}
                </small>
              </div>
              <div className="mb-3">
                <label htmlFor="selectedIntrests" className="form-label">
                  Technologies you might be Interested in{" "}
                  <sup class="text-danger">*</sup>
                </label>

                <Select
                  required
                  className="select-tech"
                  placeholder="Select any 3 technologies"
                  isMulti
                  isSearchable
                  name="selectedIntrests"
                  {...register("selectedIntrests")}
                  onChange={(selectedOptions) => {
                    setSelectedIntrests(selectedOptions);
                  }}
                  value={selectedIntrests}
                  isOptionDisabled={() => selectedIntrests.length >= 3}
                  options={courceOptions}
                />

                {/* <small className="form-text m-0 d-block">
                  select any 3 technologies
                </small> */}

                <small className="form-text d-block my-2 text-red">
                  {errors.selectedIntrests?.message}
                </small>
              </div>
              <div className="mb-3">
                <label htmlFor="password" className="form-label">
                  Password <sup class="text-danger">*</sup>
                </label>
                <div style={{ position: "relative" }}>
                  <input
                    {...register("password")}
                    className="form-control"
                    placeholder="Password"
                    type={`${eyes.password1 ? "text" : "password"}`}
                  />
                  <span
                    style={{
                      right: "0",
                      top: "-3px",
                      position: "absolute",
                      fontSize: "17px",
                    }}
                    className="btn"
                    onClick={() =>
                      setEyes({ ...eyes, password1: !eyes.password1 })
                    }
                  >
                    {eyes.password1 ? (
                      <VscEyeClosed className="text-secondary" />
                    ) : (
                      <VscEye className="text-secondary" />
                    )}
                  </span>
                </div>
                <small className="form-text d-block my-2 text-red">
                  {errors.password?.message}
                </small>
              </div>
              <div className="mb-3">
                <label htmlFor="confirmPassword" className="form-label">
                  Confirm Password <sup class="text-danger">*</sup>
                </label>
                <div style={{ position: "relative" }}>
                  <input
                    {...register("confirmPassword")}
                    className="form-control"
                    placeholder="Re-enter your password"
                    type={`${eyes.password2 ? "text" : "password"}`}
                  />
                  <span
                    style={{
                      right: "0",
                      top: "-3px",
                      position: "absolute",
                      fontSize: "17px",
                    }}
                    onClick={() =>
                      setEyes({ ...eyes, password2: !eyes.password2 })
                    }
                    className="btn"
                  >
                    {eyes.password2 ? (
                      <VscEyeClosed className="text-secondary" />
                    ) : (
                      <VscEye className="text-secondary" />
                    )}
                  </span>
                </div>
                <small className="form-text d-block my-2 text-red">
                  {errors.confirmPassword?.message}
                </small>
              </div>
              <div className="footer text-center pt-4">
                <button
                  type="submit"
                  onClick={handleClick}
                  className="btn btn-blue"
                >
                  Submit
                </button>
              </div>
            </form>
            <div className="d-flex justify-content-center">
              <p className="m-0 p-0">
                Already have an account?
                <a
                  href="/login"
                  title="Login"
                  className="mx-2 font-16 text-primary font-weight-400 lead"
                >
                  Log in
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* <Footer /> */}
    </>
  );
}
