import axios from "axios";
import variable from "../servicesvariable";
class BatchManagement {

  CreateBatch(obj, token) {
    return axios.post(variable.TUTORIAL_API_BATCHMANAGEMENT_URL + "/", obj, {
      headers: {
        Authorization: token,
      },
    });
  }
  GetAllBatch(token, userData, pageName) {
    let headers = {};
    if (!pageName) {
      headers = {
        Authorization: token,
      };
    } else {
      headers = {
        Authorization: token,
        pageName: pageName,
        userRole: userData?.userRole,
        userId: userData?.userId,
      };
    }
    return axios.get(variable.TUTORIAL_API_BATCHMANAGEMENT_URL, {
      headers,
    });
  }

  GetAllBatchByStatus(token, data, page) {
    let headers = {};
    if (!page) {
      headers = {
        Authorization: token,
      };
    } else {
      headers = {
        Authorization: token,
        page: page,
      };
    }
    return axios.post(variable.TUTORIAL_API_BATCHMANAGEMENT_URL + "/filterByStatus", data, {
      headers,
    });
  }

  getAllBatchFilterWithName(token, data) {
    return axios.post(
      variable.TUTORIAL_API_BATCHMANAGEMENT_URL + "filterByName",
      data,
      {
        headers: {
          Authorization: token,
        },
      }
    );
  }
  getBatchByName(token, info) {
    return axios.post(
      variable.TUTORIAL_API_BATCHMANAGEMENT_URL + "batch-by-name/filterBatch",
      info,
    );
  }
  deleteOneBatch(batch, token) {
    return axios.put(
      variable.TUTORIAL_API_BATCHMANAGEMENT_URL + "delete-One-Batch/" + batch._id, batch,
      {
        headers: {
          Authorization: token,
        },
      }
    );
  }
  getBatchById(id, token) {
    return axios.get(
      variable.TUTORIAL_API_BATCHMANAGEMENT_URL + "getBatchById/" + id,
      {
        headers: {
          Authorization: token,
        },
      }
    );
  }

  removeUserFromBatch(obj, token) {
    return axios.post(
      variable.TUTORIAL_API_BATCHMANAGEMENT_URL + "remove-user-from-batch",
      obj,
      {
        headers: {
          Authorization: token,
        },
      }
    );
  }

  updateBatch(id, obj, token) {
    return axios.put(
      variable.TUTORIAL_API_BATCHMANAGEMENT_URL + "updateBatch/" + id,
      obj,
      {
        headers: {
          Authorization: token,
        },
      }
    );
  }

  getSonarInfo(empid, token) {
    return axios.get(
      variable.TUTORIAL_API_BATCHMANAGEMENT_URL + "get-sonar-info/" + empid,
      {
        headers: {
          Authorization: token,
        },
      }
    );
  }

  getDepartments(token) {
    return axios.get(
      variable.TUTORIAL_API_BATCHMANAGEMENT_URL + "departments/",
      {
        headers: {
          Authorization: token,
        },
      }
    );
  }

  postDepartments(body, token) {
    return axios.post(
      variable.TUTORIAL_API_BATCHMANAGEMENT_URL + "department",
      body,
      {
        headers: {
          Authorization: token,
        },
      }
    );
  }

  getParentBatches(token) {
    return axios.get(
      variable.TUTORIAL_API_BATCHMANAGEMENT_URL + "batch-parents/",
      {
        headers: {
          Authorization: token,
        },
      }
    );
  }

  postParentBatches(body, token) {
    return axios.post(
      variable.TUTORIAL_API_BATCHMANAGEMENT_URL + "batch-parent",
      body,
      {
        headers: {
          Authorization: token,
        },
      }
    );
  }
  updateParentBatches(batchId, body, token) {
    return axios.put(
      variable.TUTORIAL_API_BATCHMANAGEMENT_URL + "batch-parent/" + batchId,
      body,
      {
        headers: {
          Authorization: token,
        },
      }
    );
  }

  deleteParentBatches(batchId, token) {
    return axios.put(
      variable.TUTORIAL_API_BATCHMANAGEMENT_URL + "batch-parent/delete-batch-parent/" + batchId,
      batchId,
      {
        headers: {
          Authorization: token,
        },
      }
    );
  }
  getResultByBatchParent(id, token) {
    return axios.get(
      variable.TUTORIAL_API_BATCHMANAGEMENT_URL +
      "result-by-parent-batch/" +
      id,
      {
        headers: {
          Authorization: token,
        },
      }
    );
  }
  getResultByBatch(id, token) {
    return axios.get(
      variable.TUTORIAL_API_BATCHMANAGEMENT_URL +
      "result-by-training-batch/" +
      id,
      {
        headers: {
          Authorization: token,
        },
      }
    );
  }
  createRepo(token, fname, lname, empCode, githubUserName, suffix, skills) {
    return axios.post(
      variable.SONAR_URL + "repo/create",
      { skills },
      {
        params: {
          firstName: fname,
          lastName: lname,
          empCode: empCode,
          githubUserName: githubUserName,
          suffix,
        },
        headers: {
          Authorization: token,
        },
      }
    );
  }
  removeRepo(fname, lname, empCode, githubUserName, suffix, token) {
    return axios.get(variable.SONAR_URL + "repo/remove", {
      params: {
        firstName: fname,
        lastName: lname,
        empCode: empCode,
        githubUserName: githubUserName,
        suffix,
      },
      headers: {
        Authorization: token,
      },
    });
  }
  deleteRepo(fname, lname, empCode, githubUserName, suffix, token) {
    return axios.delete(variable.SONAR_URL + "repo/delete", {
      params: {
        firstName: fname,
        lastName: lname,
        empCode: empCode,
        githubUserName: githubUserName,
        suffix,
      },
      headers: {
        Authorization: token,
      },
    });
  }
  createSonar(fname, lname, empCode, githubUserName, suffix, token) {
    return axios.get(variable.SONAR_URL + "sonar/create", {
      params: {
        firstName: fname,
        lastName: lname,
        empCode: empCode,
        githubUserName: githubUserName,
        suffix,
      },
      headers: {
        Authorization: token,
      },
    });
  }
  deleteSonar(fname, lname, empCode, githubUserName, suffix, token) {
    return axios.get(variable.SONAR_URL + "sonar/delete", {
      params: {
        firstName: fname,
        lastName: lname,
        empCode: empCode,
        githubUserName: githubUserName,
        suffix,
      },
      headers: {
        Authorization: token,
      },
    });
  }
  getAllAttendanceMis(token) {
    return axios.get(
      variable.TUTORIAL_API_BATCHMANAGEMENT_URL + "mis-attendance/",
      {
        headers: {
          Authorization: token,
        },
      }
    );
  }
  getsingleAttendanceMis(data, token) {
    return axios.post(
      variable.TUTORIAL_API_BATCHMANAGEMENT_URL + "mis-singleattendance/",
      data,
      {
        headers: {
          Authorization: token,
        },
      }
    );
  }
  getUpdateUserBatch(userId, data, token) {
    return axios.put(
      variable.TUTORIAL_API_BATCHMANAGEMENT_URL + "update-user-batch/" + userId,
      data,
      {
        headers: {
          Authorization: token,
        },
      }
    );
  }

  // add day in batch

  getDayBatch(token) {
    return axios.get(
      variable.TUTORIAL_API_ADDDAYINBATCH_URL + "day-batch-list",
      {
        headers: {
          Authorization: token,
        },
      }
    );
  }
  postDayBatch(obj, token) {
    return axios.post(variable.TUTORIAL_API_ADDDAYINBATCH_URL, obj, {
      headers: {
        Authorization: token,
      },
    });
  }
  updateDayBatch(obj, id, token) {
    return axios.put(
      variable.TUTORIAL_API_ADDDAYINBATCH_URL + "updateData/" + id,
      obj,
      {
        headers: {
          Authorization: token,
        },
      }
    );
  }
  deleteDayBatch(id, token) {
    return axios.put(
      variable.TUTORIAL_API_ADDDAYINBATCH_URL + "delete-day-in-batch/" + id, id,
      {
        headers: {
          Authorization: token,
        },
      }
    );
  }
  updateSkillDays(id, skill, token) {
    return axios.post(
      variable.TUTORIAL_API_BATCHMANAGEMENT_URL + "update-Skill-days/" + id,
      skill,
      {
        headers: {
          Authorization: token,
        },
      }
    );
  }
  getBatchInfoData(batchData, batchId, token) {
    return axios.post(
      variable.TUTORIAL_API_BATCH_URL + "/batch-info/" + batchId,
      batchData,
      {
        headers: {
          Authorization: token,
        },
      }
    );
  }

  getBatchRelatedUserResults(changeEventName, filterValues, token) {
    return axios.post(
      variable.TUTORIAL_API_BATCH_URL + "/get-batch-related-user-results/" + changeEventName, filterValues,
      {
        headers: {
          Authorization: token,
        },
      }
    );
  }
  getUserExamMcqs(examId, userId,examType, token) {
    return axios.get(
      variable.TUTORIAL_API_Exam_URL + "get-user-exam-mcqs/" + examId + '/' + userId + '/' + examType,
      {
        headers: {
          Authorization: token,
        },
      }
    );
  }
}
export default new BatchManagement();
