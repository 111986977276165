import { React, useState, useEffect } from "react";
import FullCalendar, { formatDate } from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { BsArrowUp, BsArrowDown } from "react-icons/bs";
import CalendarCss from "./Calendar.scss";
import CourseService from "../../services/CourseService";
import BatcheManagementService from "../../services/BatchManagementServices";
import jwtDecode from "jwt-decode";
import Select from "react-select";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../loader/Loader";
import HandleMessage from "../HandleMessage/HandleMessages";

export default function Calendar() {

  const navigate = useNavigate();
  const [calendarData, setCalendarData] = useState([])
  const [studentBatches, setStudentBatches] = useState([]);
  const [btn, setbtn] = useState("");
  const [btnn, setbtnn] = useState("");
  const [selectedEvent, setSelectedEvent] = useState({ selected: false });
  const [users, setUsers] = useState([]);
  const [batches, setBatches] = useState([]);
  const [batch, setBatch] = useState([]);
  const [token, setToken] = useState("");
  const [events, setEvents] = useState([]);
  const [attendanceFound, setAttendanceFound] = useState(false);
  const [searchUser, setSearchUser] = useState("");
  const [batchesOptions, setBatchesOptions] = useState([]);
  const [message, setMessage] = useState({ msg: "", status: false })
  const [confirmBox, setConfirmBox] = useState(false)
  const [loader, setLoader] = useState(false)
  const [attednaceStatus, setAttednaceStatus] = useState("")


  useEffect(() => {
    getTrainingBatches();
  }, []);

  const handleMessage = (message, status, magtime = false) => {
    window.scroll(0, 0)
    setConfirmBox(true)
    setMessage({ msg: message, status: status })
    if (magtime) {
      setTimeout(() => setConfirmBox(false), 5000)
    }
  }

  const handleCatchError = (err) => {
    setLoader(false)
    if (err["response"]) {
      if (err["response"].status === 401) {
        if (err["response"]?.data?.message === "You are not authorized to view this content") {
          handleMessage(err["response"].data.message, false)
        } else {
          localStorage.removeItem("token");
          navigate("/login");
        }
      } else if (err["response"].status === 404) {
        handleMessage(err["response"]?.data?.message, false)
      } else {
        localStorage.removeItem("token");
        navigate("/login");
      }
    }
  }

  const handleToken = () => {
    const userRole = jwtDecode(localStorage.getItem("token")).role !== "Student" ? true : false;
    return userRole
  }



  const getTrainingBatches = () => {
    const token = localStorage.getItem("token");
    if (token) {
      setToken(token);
      setLoader(true)
      BatcheManagementService.GetAllBatch(token, { userRole: !handleToken(), userId: jwtDecode(token)?.id }, "calendarPage")
        .then(async (res) => {
          if (res.data.status) {
            setLoader(false)
            if (!handleToken()) {
              setBatches(res.data.batchData)
              setBatch(res.data.batchData[0])
              setStudentBatches(res.data.studentBatchesList)
              getTrainingBatch(res.data.batchData[0]._id, !handleToken())
            } else {
              setBatches(res.data.batchData)
              setBatchesOptions(res.data.allBatchesList)
            }
          }
        })
        .catch((error) => handleCatchError(error));
    }
  };

  const getTrainingBatch = (batchId, userRoleStatus = false) => {
    const token = localStorage.getItem("token");
    setEvents([]);
    if (userRoleStatus) {
      if (batches?.length > 0) {
        const studentBatch = batches.find((i) => i._id === batchId);
        setBatch(studentBatch);
      }
    } else {
      if (batches?.length > 0) {
        const studentBatch = batches.find((i) => i._id === batchId)
        const tempUsers = [];
        for (let i = 0; i < studentBatch.users.length; i++) {
          const temp = {
            user: studentBatch.users[i],
            attendance: true,
          };
          tempUsers.push(temp);
        }
        setUsers([...tempUsers]);
        setBatch(studentBatch);
      }
    }
    if (token) {
      CourseService.getEvents(batchId, token)
        .then((res) => {
          if (res.data.status) {
            setEvents([...res.data.calenderData]);
            setCalendarData(res.data.calenderData)
          }
        })
        .catch((error) => handleCatchError(error));
    }
  };


  const changeAttendance = (studentId) => {
    const tempUsers = [...users];
    const student = tempUsers.find((i) => i.user._id === studentId);
    student.attendance = !student.attendance;
    setUsers([...tempUsers]);
  };
  const handleSaveAttendance = () => {
    const skill = selectedEvent.clickInfo.event.title.split(" ");
    const tempAttendance = [];
    for (let i = 0; i < users.length; i++) {
      const user = users[i];
      const temp = {
        user: user.user._id,
        attendance: user.attendance,
      };
      tempAttendance.push(temp);
    }
    const temp = {
      trainingBatchId: batch._id,
      skillName: skill[0],
      date: new Date(selectedEvent.clickInfo.event.start),
      day: skill[2],
      attendance: [...tempAttendance],
    };
    CourseService.postAttendance(temp, token)
      .then((res) => {
        handleMessage(res.data.message, true, true)
        setAttednaceStatus("AS")
      })
      .catch((error) => {
        handleCatchError(error)
      });
  };

  const getAttendance = (skillName, day, token) => {
    CourseService.getAttendance(skillName, day, batch._id, token)
      .then((res) => {
        if (res.data.status) {
          setAttendanceFound(true);
          setUsers(res.data.data.attendance);
        } else {
          setAttednaceStatus("")
          setAttendanceFound(false);
          const tempUsers = [];
          for (let i = 0; i < batch.users.length; i++) {
            const temp = {
              user: batch.users[i],
              attendance: true,
            };
            tempUsers.push(temp);
          }
          setUsers([...tempUsers]);
        }
      })
      .catch((error) => handleCatchError(error));
  };

  const handleSkillDate = (skillName, trainingDays) => {
    if (calendarData?.length > 0 && skillName) {
      const skillStartDateName = `${skillName} Day 1`
      const skillEndDateName = `${skillName} Day ${trainingDays}`
      const skillInternalDateName = `${skillName} Internal Exam`
      const skillExternalDateName = `${skillName} External Exam`
      const startDate = calendarData.find((item) => item.title === skillStartDateName)?.start
      const endDate = calendarData.find((item) => item.title === skillEndDateName)?.start
      const internalDate = calendarData.find((item) => item.title === skillInternalDateName)?.start
      const externalDate = calendarData.find((item) => item.title === skillExternalDateName)?.start
      return {
        startDate: startDate,
        endDate: endDate,
        internalDate: internalDate,
        externalDate: externalDate
      }
    }
  }

  const handleEventClick = async (clickInfo) => {
    try {
      if (
        !["For Practice", "Trainer on Leave", "Trainee on Leave"].find(
          (item) => item == clickInfo.event.title
        ) &&
        localStorage.getItem("token") &&
        handleToken()
      ) {
        setSelectedEvent({ ...selectedEvent, selected: true, clickInfo });
        const skill = clickInfo.event.title.split(" ");
        getAttendance(skill[0], skill[2], token);
        if (skill[0] === "TrainingProject" || skill[2] === "Exam") {
          const startdate = new Date(
            events.find(
              (skillwisedate) =>
                skillwisedate._def.title === skill[0] + " Day 1"
            )?._instance?.range?.start
          )?.toISOString();
          const enddate = new Date(
            events.find(
              (skillwisedate) =>
                skillwisedate._def.title === skill[0] + " Internal Exam"
            )?._instance?.range?.start ||
            events.find(
              (skillwisedate) =>
                skillwisedate._def.title === skill[0] + " External Exam"
            )?._instance?.range?.start
          );
          const endDateValue = new Date(enddate.setDate(enddate.getDate() - 1))
            ?.toISOString()
            ?.slice(0, 10)
          const examType = skill[1] === 'Internal' ? 'internalExam' : 'externalExam';
          window.open(`/batchinfo/${skill[0]}/${examType}/${batch._id}?startDate=${startdate?.slice(0, 10)}&endDate=${endDateValue}`, "_blank");
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleEvents = (events) => {
    setEvents([...events]);
  };


  function dateFormat(date) {
    const today = new Date(date);
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();
    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;
    return dd + "/" + mm + "/" + yyyy;
  }

  return (
    <div className="course-calendar p-xl-5 p-lg-4 p-3">
      <div className="container-fluid">
        {loader &&
          <Loader loaderStatus={loader} message="Loading ....." />
        }
        <div className="row">
          <div className="col-12">
            <div className="calendar-top bg-light-gray calendar-top p-3">
              {confirmBox && <HandleMessage message={message} />}
              {/* {notification.confirm && (
                <div
                  class={`alert  alert-dismissible fade show ${notification.success ? "alert-success" : "alert-danger"
                    }`}
                  role="alert"
                >
                  {notification.msg}
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="alert"
                    aria-label="Close"
                  ></button>
                </div>
              )} */}
              {batch?.skills &&
                batch.skills.length > 0 &&
                <div className="d-flex justify-content-center flex-wrap mb-3">
                  {batch.skills.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className="py-2 px-3 mx-1 text-light badge my-1 font-weight-500 font-14"
                        style={{ backgroundColor: item.skillId.color || "#0000FF" }}
                      >
                        {item?.skillId.name}
                      </div>
                    );
                  })}
                </div>
              }
              {batches &&
                batches.length > 0 &&
                localStorage.getItem("token") &&
                handleToken() ? (
                <Select
                  options={batchesOptions}
                  onChange={(e) => {
                    getTrainingBatch(e.value)
                    setSelectedEvent({ selected: false })
                  }}
                  styles={{
                    ///.....
                    menu: (provided) => ({ ...provided, zIndex: 9999 }),
                    ///.....
                  }}
                />
              ) : (
                studentBatches &&
                studentBatches.length > 1 && (
                  <Select
                    options={studentBatches}
                    onChange={(e) => getTrainingBatch(e.value, true)}
                    styles={{
                      ///.....
                      menu: (provided) => ({ ...provided, zIndex: 9999 }),
                      ///.....
                    }}
                  />
                )
              )}
            </div>
            {batch && batch._id ? (
              <div className="calendar-cource mt-4 py-2 px-3 bg-light-gray bl-5">
                <ul className="list-inline mb-0">
                  <li className="list-inline-item me-4 d-xl-inline-block d-block my-2 my-xl-0">
                    <strong className="font-weight-600">Batch: </strong>
                    {batch.name}
                  </li>
                  <li className="list-inline-item me-4 d-xl-inline-block d-block my-2 my-xl-0">
                    <strong className="font-weight-600"> Days:</strong> {batch.days + batch.skills.length * 2}
                  </li>
                  <li className="list-inline-item me-4 d-xl-inline-block d-block my-2 my-xl-0">
                    <strong className="font-weight-600"> Start date:</strong> {dateFormat(batch.startDate)}
                  </li>
                  <li className="list-inline-item me-4 d-xl-inline-block d-block my-2 my-xl-0">
                    <strong className="font-weight-600">End date:</strong>{" "}
                    {events.length > 0 &&
                      `${dateFormat(events[events.length - 1].start)}`}
                  </li>
                  <li className="list-inline-item me-4 d-xl-inline-block d-block my-2 my-xl-0">
                    <strong className="font-weight-600">Department: </strong>
                    {batch.department.name}
                    {/* {batch.department && <><strong className="font-weight-600">Department:</strong> {batch.department.name}</>} */}
                  </li>
                </ul>
              </div>
            ) : (
              <>
                {jwtDecode(localStorage.getItem("token")).role === "Student" && (
                  <div className="calendar-cource mt-4 py-2 px-3 bg-light-gray rounded-3">
                    <h4 className="mt-2 mb-0">Please ask your trainer to add in batch.</h4>
                  </div>
                )}
              </>
            )}
            <hr className="my-5" />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-8 col-xl-9 calendar-block mb-5 mb-lg-0">
            {events.length > 0 && (
              <FullCalendar
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                headerToolbar={{
                  left: "prev,next today",
                  center: "title",
                  right: "dayGridMonth,timeGridWeek,timeGridDay",
                }}
                initialView="dayGridMonth"
                editable={false}
                selectable={true}
                selectMirror={true}
                dayMaxEvents={true}
                weekends={true}
                initialEvents={[...events]} // alternatively, use the `events` setting to fetch from a feed
                // eventContent={renderEventContent} // custom render function
                eventClick={handleEventClick}
                eventsSet={handleEvents} // called after events are initialized/added/changed/removed
                // initialDate={batch && batch.startDate}
                initialDate={new Date()}
              />
            )}
          </div>
          <div className="col-lg-4 col-xl-3">
            {
              <>
                {selectedEvent.selected && users.length > 0 ? (
                  <>
                    <h2 className="mb-3 h3">
                      {selectedEvent.clickInfo.event.title}
                    </h2>
                    <h6>Students - {users.length}</h6>
                    <div class="mt-2 mb-4">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter User Name"
                        value={searchUser}
                        onChange={(e) => setSearchUser(e.target.value)}
                      />
                    </div>
                    {/* <hr /> */}
                    <table className="table border-0">
                      <thead>
                        <tr>
                          <th>
                            Attendance :
                          </th>
                          <th className="cursor-pointer"
                            onClick={() => {
                              setbtn(
                                btn == ""
                                  ? "BsArrowDown"
                                  : btn == "BsArrowUp"
                                    ? "BsArrowDown"
                                    : "BsArrowUp"
                              );
                              setbtnn("");
                            }}
                          >
                            {btn == "BsArrowUp" ? (
                              <BsArrowUp className="fs-6" />
                            ) : (
                              <BsArrowDown className="fs-6" />
                            )}{" "}
                            (ByName)
                          </th>
                          <th>-</th>
                          <th className="cursor-pointer"
                            onClick={() => {
                              setbtnn(
                                btnn == ""
                                  ? "BsArrowDown"
                                  : btnn == "BsArrowUp"
                                    ? "BsArrowDown"
                                    : "BsArrowUp"
                              );
                              setbtn("");
                            }}
                          >
                            {btnn == "BsArrowUp" ? (
                              <BsArrowUp className="fs-6" />
                            ) : (
                              <BsArrowDown className="fs-6" />
                            )}{" "}
                            (ById)
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {users
                          .sort((a, b) => {
                            if (btn == "" && btnn == "") {
                              if (a.user.username > b.user.username) {
                                return 1;
                              } else if (a.user.username < b.user.username) {
                                return -1;
                              }
                            } else if (btn == "BsArrowDown" && btnn == "") {
                              if (a.user.username > b.user.username) {
                                return 1;
                              } else if (a.user.username < b.user.username) {
                                return -1;
                              }
                            } else if (btn == "BsArrowUp" && btnn == "") {
                              if (a.user.username < b.user.username) {
                                return 1;
                              } else if (a.user.username > b.user.username) {
                                return -1;
                              }
                            } else if (btn == "" && btnn == "BsArrowDown") {
                              if (a.user.empId > b.user.empId) {
                                return 1;
                              } else if (a.user.empId < b.user.empId) {
                                return -1;
                              }
                            } else if (btn == "" && btnn == "BsArrowUp") {
                              if (a.user.empId < b.user.empId) {
                                return 1;
                              } else if (a.user.empId > b.user.empId) {
                                return -1;
                              }
                            }
                            return 0;
                          })
                          .filter((item1) => {
                            if (item1.user.deactivate) {
                              return;
                            }
                            if (searchUser === "") {
                              return item1;
                            } else if (
                              item1.user.username
                                .toLowerCase()
                                .includes(searchUser.toLowerCase())
                            ) {
                              return item1;
                            }
                          })
                          .map((item, index) => {
                            return (
                              <tr key={index}>
                                <td>
                                  <div class="form-check form-switch">
                                    <input
                                      class="form-check-input"
                                      type="checkbox"
                                      checked={item.attendance}
                                      id="attendance"
                                      onChange={(e) =>
                                        changeAttendance(item.user._id)
                                      }
                                    />
                                  </div>
                                </td>
                                <td>
                                  <label class="form-check-label" for="attendance">
                                    {item.user.username}
                                  </label>
                                </td>
                                <td>-</td>
                                <td>
                                  <small>{item.user.empId}</small>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                    <button
                      type="button"
                      class="btn btn-primary w-25"
                      onClick={handleSaveAttendance}
                      disabled={attednaceStatus === "AS" || attendanceFound}
                    >
                      Save
                    </button>
                  </>
                ) : (
                  <div class="calendar-sidebar-detail list-group">
                    {batch &&
                      batch.skills &&
                      batch.skills.map((item) => {
                        let trainingDays = item?.days > 0 ? item.days : item.trainingPlansId?.length > 0 ? item.trainingPlansId.length : 0;
                        if (item.skillId.name === "TrainingProject") {
                          const trainingProjectDays = events.filter((i) =>
                            i.title.startsWith("TrainingProject")
                          );
                          trainingDays = trainingProjectDays.length - 2;
                        }
                        let handleDates = handleSkillDate(item.skillId.name, trainingDays)
                        return (
                          <div
                            className="d-flex text-primary course-link cursor-pointer justify-content-between text-decoration-none align-items-center list-group-item list-group-item-action bl-5 mb-3 border-1"
                            target="_blank"
                          >
                            <div>
                              <Link
                                to={`/course/${item.skillId.name}/courseOverview/${item.skillId.name}/tplan`}
                                target="_blank"
                                className="text-primary cursor-pointer"
                              >
                                {item.skillId.name}
                                <span className="ms-1">{`(${trainingDays} Days)`}</span>
                              </Link>
                              <div className="mt-2">
                                <Link to={handleToken() ? `/batchinfo/${item?.skillId?.name}/internalExam/${batch?._id}?startDate=${handleDates?.startDate}&endDate=${handleDates?.endDate}` : "#"}
                                  target={handleToken() && "_blank"}
                                  style={{ textDecoration: !handleToken() && "none" }}
                                  className="text-primary cursor-pointer d-block d-sm-inline-block"
                                >
                                  I :{" "}{handleDates?.internalDate}
                                </Link>
                                <Link
                                  to={handleToken() ? `/batchinfo/${item?.skillId?.name}/externalExam/${batch?._id}?startDate=${handleDates?.startDate}&endDate=${handleDates?.endDate}` : "#"}
                                  target={handleToken() && "_blank"}
                                  style={{ textDecoration: !handleToken() && "none" }}
                                  className="text-primary cursor-pointer ms-0 ms-sm-4 d-block d-sm-inline-block">
                                  E :{" "}{handleDates?.externalDate}
                                </Link>
                              </div>
                            </div>
                            <i class="fa-solid fa-arrow-right-long ms-3"></i>
                          </div>
                        );
                      })}
                  </div>
                )}
              </>
            }
          </div>
        </div>
      </div>
    </div>
  );
}
