import axios from "axios";
import variable from "../servicesvariable";

export const imgURL = variable.imgURL;
export const pptURL = variable.pptURL;
export const fileURL = variable.fileURL;

// Account disable after 25 months (8/10/2021) then create new account and
// change CLOUDNARY_RAW_UP = "https://api.cloudinary.com/v1_1/<cloud name>/raw/upload
const CLOUDNARY_RAW_UP = "https://api.cloudinary.com/v1_1/change/raw/upload";

class CourseService {
  getCourses() {
    return axios.get(variable.TUTORIAL_API_COURSE_URL);
  }

  getCategory() {
    return axios.get(variable.TUTORIAL_API_CATEGORY_URL);
  }
  getCategoryByID(category) {
    return axios.get(variable.TUTORIAL_API_CATEGORY_URL + "/" + category);
  }
  getCategoryByName(category) {
    return axios.get(variable.TUTORIAL_API_CATEGORY_URL + "/name/" + category);
  }
  postCategory(category, token) {
    return axios.post(variable.TUTORIAL_API_CATEGORY_URL, category, {
      headers: {
        Authorization: token,
      },
    });
  }
  putCategory(category1, category2, token) {
    let cate = { cname: category1 };
    return axios.put(
      variable.TUTORIAL_API_CATEGORY_URL + "/" + category2,
      cate,
      {
        headers: {
          Authorization: token,
        },
      }
    );
  }
  deleteCategory(category, token) {
    return axios.put(
      variable.TUTORIAL_API_CATEGORY_URL + "/del-category/" + category,
      category,
      {
        headers: {
          Authorization: token,
        },
      }
    );
  }

  getSingleCourse(name) {
    return axios.get(variable.TUTORIAL_API_COURSE_URL + name);
  }
  getCoursesIn(courses) {
    return axios.post(variable.TUTORIAL_API_COURSE_URL, courses);
  }

  // api: course apis
  // access: private
  postCourse(course, token) {
    return axios.post(variable.TUTORIAL_API_API_URL, course, {
      headers: {
        Authorization: token,
      },
    });
  }

  // api: content add in traingplan apis
  // access: private
  getAllContent(token) {
    return axios.get(variable.TUTORIAL_API_CONTENT_URL + "allRequest", {
      headers: {
        Authorization: token,
      },
    });
  }

  postAddContent(addContent, token) {
    return axios.post(
      variable.TUTORIAL_API_CONTENT_URL + "addContent",
      addContent,
      {
        headers: {
          Authorization: token,
        },
      }
    );
  }

  putContent(addContent, contentId, token) {
    return axios.put(
      variable.TUTORIAL_API_CONTENT_URL + contentId,
      addContent,
      {
        headers: {
          Authorization: token,
        },
      }
    );
  }

  createClone(addContent, token) {
    return axios.post(
      variable.TUTORIAL_API_CONTENT_URL + "/create-clone",
      addContent,
      {
        headers: {
          Authorization: token,
        },
      }
    );
  }

  // api: exams apis
  // access: private
  getExamSchedule(token) {
    return axios.get(variable.TUTORIAL_API_Exam_URL + "examSchedules", {
      headers: {
        Authorization: token,
      },
    });
  }

  getExamScheduleForCreateExam(token, role, pageNumber) {
    return axios.get(
      variable.TUTORIAL_API_Exam_URL + "examSchedulesForCreateExam/" + role + "/" + pageNumber,
      {
        headers: {
          Authorization: token,
        },
      }
    );
  }

  getSingleExamSchedule(examId, userId, token) {
    return axios.get(
      variable.TUTORIAL_API_Exam_URL +
      "singleExamSchedule/" +
      examId +
      "/" +
      userId,
      {
        headers: {
          Authorization: token,
        },
      }
    );
  }

  CreateExamSchedules(token, scheduleData) {
    return axios.post(
      variable.TUTORIAL_API_Exam_URL + "createExam",
      scheduleData,
      {
        headers: {
          Authorization: token,
        },
      }
    );
  }

  updateExamSchedules(token, scheduleData, examId) {
    return axios.put(
      variable.TUTORIAL_API_Exam_URL + "updateschedule/" + examId,
      scheduleData,
      {
        headers: {
          Authorization: token,
        },
      }
    );
  }

  deleteExamSchedule(token, id) {
    return axios.put(variable.TUTORIAL_API_Exam_URL + "delete-exam/" + id, id, {
      headers: {
        Authorization: token,
      },
    });
  }

  getFindUserExamSchedule(examId, userId, token) {
    return axios.get(
      variable.TUTORIAL_API_Exam_URL +
      "getFindExamSchedule/" +
      examId +
      "/" +
      userId,
      {
        headers: {
          Authorization: token,
        },
      }
    );
  }

  getMergeVideos(skillId, batchId, examId, token) {
    return axios.get(
      variable.TUTORIAL_API_Exam_URL +
      "getMergeVideos/" +
      examId +
      "/" +
      skillId +
      "/" +
      batchId,
      {
        headers: {
          Authorization: token,
        },
      }
    );
  }

  getExamQuestions(token, examId, userId) {
    return axios.get(
      variable.TUTORIAL_API_Exam_URL + "questions/" + examId + "/" + userId,
      {
        headers: {
          Authorization: token,
        },
      }
    );
  }

  getExamPracticalQuestions(examId) {
    return axios.get(
      variable.TUTORIAL_API_Exam_URL + "practicalquestions/" + examId
    );
  }

  getExamQuestionLength(schedule, token) {
    return axios.post(
      variable.TUTORIAL_API_Exam_URL + "questionslength",
      schedule,
      {
        headers: {
          Authorization: token,
        },
      }
    );
  }
  getMultipleSkillExamQuestionLength(schedule, token) {
    return axios.post(
      variable.TUTORIAL_API_Exam_URL + "questionslength-multiple-skill",
      schedule,
      {
        headers: {
          Authorization: token,
        },
      }
    );
  }

  getExamSingleQuestion(token, Id) {
    return axios.get(variable.TUTORIAL_API_Exam_URL + "singleQuestion/" + Id, {
      headers: {
        Authorization: token,
      },
    });
  }

  postExamAnswer(token, studentDataResult) {
    return axios.post(
      variable.TUTORIAL_API_Exam_URL + "asnswerStore",
      studentDataResult,
      {
        headers: {
          Authorization: token,
        },
      }
    );
  }

  putCourse(course, token) {
    return axios.put(variable.TUTORIAL_API_API_URL + course._id, course, {
      headers: {
        Authorization: token,
      },
    });
  }
  removeCourse(course, token) {
    return axios.put(
      variable.TUTORIAL_API_API_URL + "del-course/" + course._id,
      course,
      {
        headers: {
          Authorization: token,
        },
      }
    );
  }
  getSubCoursesIn(courses, token) {
    return axios.post(
      variable.TUTORIAL_API_COURSE_URL + "subcourse/",
      courses,
      {
        headers: {
          Authorization: token,
        },
      }
    );
  }
  getSubCoursesByType(courses, token) {
    return axios.post(
      variable.TUTORIAL_API_COURSE_URL + "subcourses/",
      { courses: courses },
      {
        headers: {
          Authorization: token,
        },
      }
    );
  }

  getSubCourses() {
    return axios.get(variable.TUTORIAL_API_SUBCOURSE_URL);
  }
  getSingleSubCourse(name) {
    return axios.get(variable.TUTORIAL_API_COURSE_URL + "subcourse/" + name);
  }

  getSinglesubcourse(id) {
    return axios.get(
      variable.TUTORIAL_API_COURSE_URL + id + "/singlesubcourse"
    );
  }

  findSinglesubcourse(id) {
    return axios.get(variable.TUTORIAL_API_SUBCOURSE_URL + "/" + id);
  }

  // api: course apis
  // access: private
  postSubCourse(subcourse, token) {
    return axios.post(variable.TUTORIAL_API_SUBCOURSE_URL, subcourse, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: token,
      },
    });
  }

  putSubCourses(subcourse, subcourseId, token) {
    return axios.put(
      variable.TUTORIAL_API_SUBCOURSE_URL + "/" + subcourseId,
      subcourse,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: token,
        },
      }
    );
  }
  deleteSubCourse(subcourse, token) {
    return axios.put(
      variable.TUTORIAL_API_SUBCOURSE_URL + "/del-subcourse/" + subcourse._id,
      subcourse,
      {
        headers: {
          Authorization: token,
        },
      }
    );
  }

  // api: trainingplan apis
  // access: private
  getTrainingPlan(cname, token) {
    return axios.get(
      variable.TUTORIAL_API_COURSE_URL + cname + "-training-plan",
      {
        headers: {
          Authorization: token,
        },
      }
    );
  }
  getTrainingPlanByID(cname, token) {
    return axios.get(variable.TUTORIAL_API_COURSE_URL + "cid/" + cname, {
      headers: {
        Authorization: token,
      },
    });
  }
  getDescription(cname) {
    return axios.get(variable.TUTORIAL_API_COURSE_URL + cname + "/des");
  }
  postTrainingPlan(trainingplan, formdata, token) {
    return axios.post(
      variable.TUTORIAL_API_SUBCOURSE_URL +
      "/" +
      trainingplan.cid +
      "/trainingplan/",
      formdata,
      {
        headers: {
          Authorization: token,
        },
      }
    );
  }
  postTplanXlsx(cid, tplans, token) {
    return axios.post(
      variable.TUTORIAL_API_SUBCOURSE_URL + "/" + cid + "/trainingplan/xlsx",
      tplans,
      {
        headers: {
          Authorization: token,
        },
      }
    );
  }
  postTrainingPlanImg(imgdata, token) {
    return axios.post(variable.TUTORIAL_API_API_URL + "/image/", imgdata, {
      headers: {
        Authorization: token,
        "Content-Type": "multipart/form-data",
      },
    });
  }
  putTrainingPlan(trainingplan, formdata, token) {
    return axios.put(
      variable.TUTORIAL_API_SUBCOURSE_URL +
      "/" +
      trainingplan.cid +
      "/trainingplan/" +
      trainingplan._id,
      formdata,
      {
        headers: {
          Authorization: token,
        },
      }
    );
  }
  removeTplan(plan, token) {
    return axios.put(
      variable.TUTORIAL_API_SUBCOURSE_URL +
      "/" +
      plan.cid +
      "/trainingplan/delete-training-plan/" +
      plan.tp_day,
      plan,
      {
        headers: {
          Authorization: token,
        },
      }
    );
  }

  // api: ppts apis
  // access: private
  getPpts(cname, token) {
    return axios.get(variable.TUTORIAL_API_COURSE_URL + cname + "-ppts", {
      headers: {
        Authorization: token,
      },
    });
  }
  getCoursePpts(cname, token) {
    return axios.get(
      variable.TUTORIAL_API_SUBCOURSE_URL + "/" + cname + "/ppt",
      {
        headers: {
          Authorization: token,
        },
      }
    );
  }
  postCourseppt(cppt, token) {
    return axios.post(
      variable.TUTORIAL_API_SUBCOURSE_URL + "/" + cppt.id + "/ppt/",
      cppt,
      {
        headers: {
          Authorization: token,
        },
      }
    );
  }
  putPpts(pname, data, token) {
    let day = { tp_day: data, pname: pname };
    return axios.put(
      variable.TUTORIAL_API_SUBCOURSE_URL + "/" + data + "/ppt/day/ppt",
      day,
      {
        headers: {
          Authorization: token,
        },
      }
    );
  }

  postPpts(ppt) {
    return axios.post(CLOUDNARY_RAW_UP, ppt);
  }
  removePpt(cppt, deletedPpts, token) {
    return axios.put(
      variable.TUTORIAL_API_SUBCOURSE_URL + "/" + cppt.cid + "/ppt/" + cppt._id,
      { ...cppt, deletedPpts },
      {
        headers: {
          Authorization: token,
        },
      }
    );
  }

  // api: videos apis
  // access: private
  getVideos(cname, token) {
    // return axios.get(variable.TUTORIAL_API_COURSE_URL + cname + "-videos");
    return axios.get(variable.TUTORIAL_API_COURSE_URL + cname + "/videos");
  }
  getCourseVideos(cname) {
    return axios.get(variable.TUTORIAL_API_COURSE_URL + cname + "/videos");
  }
  getLocalVideo(vid, token) {
    return axios.get(variable.TUTORIAL_API_COURSE_URL + vid + "-video/local", {
      headers: {
        Authorization: token,
      },
    });
  }
  postCoursevideos(cid, viddata, token) {
    return axios.post(
      variable.TUTORIAL_API_SUBCOURSE_URL + "/" + cid + "/video/",
      viddata,
      {
        headers: {
          Authorization: token,
          "Content-Type": "multipart/form-data",
        },
      }
    );
  }
  getm3u8video() {
    return axios.get(variable.TUTORIAL_API_COURSE_URL + "videos/m3u8all");
  }
  convertM3u8Video(videos, courseName) {
    return axios.post(
      variable.TUTORIAL_API_LOGIN_URL + "video/convert/" + courseName,
      videos
    );
  }
  postm3u8video(viddata) {
    return axios.post(variable.TUTORIAL_API_COURSE_URL + "m3u8videos", viddata);
  }
  deletem3u8video(viddata, token) {
    return axios.put(
      variable.TUTORIAL_API_COURSE_URL + "deleteVideo/" + viddata,
      viddata,
      {
        headers: {
          Authorization: token,
        },
      }
    );
  }

  removeAllm3u8Video(allVideos, token) {
    return axios.put(
      variable.TUTORIAL_API_COURSE_URL + "deleteVideos",
      allVideos.dvideos,
      {
        headers: {
          Authorization: token,
        },
      }
    );
  }
  putM3U8video(id, data, token) {
    return axios.put(
      variable.TUTORIAL_API_SUBCOURSE_URL + "/" + id + "/video/m3u8video",
      data,
      {
        headers: {
          Authorization: token,
        },
      }
    );
  }
  deleteM3u8Videos(id, videoList, token) {
    return axios.delete(
      variable.TUTORIAL_API_SUBCOURSE_URL +
      "/" +
      id +
      "/video/delete-M3u8-video",
      {
        data: videoList,
        headers: {
          Authorization: token,
        },
      }
    );
  }
  CreateAndUpdateVideoTopic(id, topic, token) {
    return axios.put(
      variable.TUTORIAL_API_SUBCOURSE_URL + "/" + id + "/video/videoTopic",
      topic,
      {
        headers: {
          Authorization: token,
        },
      }
    );
  }
  putCoursevideos(vid, data, token) {
    let day = { tp_day: data };
    return axios.put(
      variable.TUTORIAL_API_SUBCOURSE_URL + "/" + vid + "/video/",
      day,
      {
        headers: {
          Authorization: token,
        },
      }
    );
  }
  putVideopdf(vname, data, token) {
    // let day ={tp_day : data }
    return axios.put(
      variable.TUTORIAL_API_SUBCOURSE_URL + "/" + vname + "/video/upload/pdf",
      data, {
      headers: {
        Authorization: token,
      },
    }
    );
  }
  removeVideo(cvideo, token) {
    return axios.put(
      variable.TUTORIAL_API_SUBCOURSE_URL +
      "/" +
      cvideo._id +
      "/video/" +
      cvideo.dvideos[0].id,
      cvideo,
      {
        headers: {
          Authorization: token,
        },
      }
    );
  }

  moveVideos(moveVideoFile, token) {
    return axios.post(
      variable.TUTORIAL_API_SUBCOURSE_URL +
      "/" +
      moveVideoFile.oldTopicId +
      "/move/" +
      "video",
      moveVideoFile,
      {
        headers: {
          Authorization: token,
        },
      }
    );
  }

  removeVideopdf(subCourseData, pdfid, token) {
    return axios.delete(
      variable.TUTORIAL_API_SUBCOURSE_URL +
      "/" +
      pdfid +
      "/video/" +
      subCourseData.subCourseId +
      "/" +
      subCourseData.subTopicName,
      // cvideo,
      {
        headers: {
          Authorization: token,
        },
      }
    );
  }

  // api: discussion apis
  // access: private
  getDescussions(cname, token) {
    return axios.get(variable.TUTORIAL_API_COURSE_URL + cname + "-discussion", {
      headers: {
        Authorization: token,
      },
    });
  }
  getPendingDescussions(cid) {
    return axios.get(
      variable.TUTORIAL_API_API_URL + cid + "/discussion/pending"
    );
  }
  postDescussion(discuss, token) {
    return axios.post(
      variable.TUTORIAL_API_API_URL + discuss.cid + "/discussion",
      discuss,
      {
        headers: {
          Authorization: token,
        },
      }
    );
  }
  postDescussionReply(reply, token) {
    return axios.post(
      variable.TUTORIAL_API_API_URL + reply.did + "/discussion/reply",
      reply,
      {
        headers: {
          Authorization: token,
        },
      }
    );
  }

  // api : progress apis
  // access: private
  getProgress(token, uid) {
    return axios.get(
      variable.TUTORIAL_API_SUBCOURSE_URL + "/" + uid + "/videoprogress/",
      {
        headers: {
          Authorization: token,
        },
      }
    );
  }
  getallProgress(token, uid) {
    return axios.get(
      variable.TUTORIAL_API_SUBCOURSE_URL + "/" + uid + "/videoprogress/all",
      {
        headers: {
          Authorization: token,
        },
      }
    );
  }
  getProgressbycname(cname, token, uid) {
    return axios.get(
      variable.TUTORIAL_API_SUBCOURSE_URL +
      "/" +
      uid +
      "/videoprogress/cname/" +
      cname,
      {
        headers: {
          Authorization: token,
        },
      }
    );
  }

  postProgress(progress, token, uid) {
    return axios.post(
      variable.TUTORIAL_API_SUBCOURSE_URL + "/" + uid + "/videoprogress/",
      progress,
      {
        headers: {
          Authorization: token,
        },
      }
    );
  }
  putProgress(id, progress, token, uid) {
    return axios.put(
      variable.TUTORIAL_API_SUBCOURSE_URL + "/" + uid + "/videoprogress/" + id,
      progress,
      {
        headers: {
          Authorization: token,
        },
      }
    );
  }

  getTrainingProgress(studentId, courseId, token) {
    return axios.get(
      `${variable.TUTORIAL_API_TRAINING_URL}${studentId}/${courseId}`,
      {
        headers: {
          Authorization: token,
        },
      }
    );
  }

  updateTrainingProgress(studentId, courseId, data, token) {
    return axios.put(
      `${variable.TUTORIAL_API_TRAINING_URL}${studentId}/${courseId}`,
      data,
      {
        headers: {
          Authorization: token,
        },
      }
    );
  }

  getPendingSubscriptionRequests(token) {
    return axios.get(
      `${variable.TUTORIAL_API_TRAINING_URL}get-pending-subs-reqs`,
      {
        headers: {
          Authorization: token,
        },
      }
    );
  }

  getStudentTrainingProgress() {
    return axios.get(
      `${variable.TUTORIAL_API_TRAINING_URL}get-training-progress`
      // {
      //   headers: {
      //     Authorization: token,
      //   },
      // }
    );
  }

  // test module
  getPracticalsQuestionsList(filterData, pagenumber, token) {
    return axios.post(variable.TUTORIAL_API_Exam_URL + "getPracticalList/" + pagenumber, filterData, {
      headers: {
        Authorization: token,
      },
    });
  }

  getMCQQuestions(filterData, pagenumber, token) {
    return axios.post(variable.TUTORIAL_API_Exam_URL + "getMCQ/" + pagenumber, filterData, {
      headers: {
        Authorization: token,
      },
    });
  }

  getAllStudentColleagueProgressedCourses(limit) {
    return axios.get(
      `${variable.TUTORIAL_API_TRAINING_URL}get-progressed-courses`,
      {
        params: {
          limit,
        },
      }
    );
  }

  // getQuestions() {
  //   return axios.get(variable.TUTORIAL_API_COURSE_URL + "questions");
  // }
  // getQuestionByID(cid, token) {
  //   return axios.get(variable.TUTORIAL_API_API_URL + cid + "/test/questions", {
  //     headers: {
  //       Authorization: token,
  //     },
  //   });
  // }
  // postQuestion(cid, formdata, token) {
  //   return axios.post(
  //     variable.TUTORIAL_API_API_URL + cid + "/test/questions/",
  //     formdata,
  //     {
  //       headers: {
  //         Authorization: token,
  //       },
  //     }
  //   );
  // }

  // batch

  getBatch() {
    return axios.get(variable.TUTORIAL_API_BATCH_URL);
  }
  getBatchByName(name) {
    return axios.get(variable.TUTORIAL_API_BATCH_URL + "/" + name);
  }
  postBatch(formdata) {
    return axios.post(variable.TUTORIAL_API_BATCH_URL, formdata);
  }
  deleteBatch(subcourse, token) {
    return axios.put(
      variable.TUTORIAL_API_BATCH_URL + "/delete-One-Batch/" + subcourse,
      subcourse,
      {
        headers: {
          Authorization: token,
        },
      }
    );
  }

  getCourseInformation(courseName, token) {
    return axios.get(
      variable.TUTORIAL_API_COURSE_URL + "courseInfo/" + courseName,
      {
        headers: {
          Authorization: token,
        },
      }
      // trainingPDF
    );
  }
  getTrainingPDF() {
    return axios.get(variable.TUTORIAL_API_TPDF_URL);
  }
  getTrainingPDFByName(name) {
    return axios.get(variable.TUTORIAL_API_TPDF_URL + "/" + name);
  }
  postTrainingPDF(formdata) {
    return axios.post(variable.TUTORIAL_API_TPDF_URL, formdata);
  }
  deleteTrainingPDF(subcourse, token) {
    return axios.put(
      variable.TUTORIAL_API_TPDF_URL + "/del-trainingPdf/" + subcourse,
      subcourse,
      {
        headers: {
          Authorization: token,
        },
      }
    );
  }

  getTrainingBatchById(id, token) {
    return axios.get(
      variable.TUTORIAL_API_BATCHMANAGEMENT_URL + "getBatchById/" + id,
      {
        headers: {
          Authorization: token,
        },
      }
    );
  }

  getEvents(batchId, token) {
    return axios.get(
      variable.TUTORIAL_API_BATCHMANAGEMENT_URL + "events/" + batchId,
      {
        headers: {
          Authorization: token,
        },
      }
    );
  }

  postAttendance(data, token) {
    return axios.post(
      variable.TUTORIAL_API_BATCHMANAGEMENT_URL + "attendance/",
      data,
      {
        headers: {
          Authorization: token,
        },
      }
    );
  }

  addSubTopicName(topic, subcourseId, token) {
    return axios.post(
      variable.TUTORIAL_API_SUBCOURSE_URL + "/add-topic/" + subcourseId,
      topic,
      {
        headers: {
          Authorization: token,
        },
      }
    );
  }

  getAttendance(skillName, day, batchId, token) {
    return axios.get(
      variable.TUTORIAL_API_BATCHMANAGEMENT_URL +
      "attendance/" +
      batchId +
      "/" +
      skillName +
      "/" +
      day,
      {
        headers: {
          Authorization: token,
        },
      }
    );
  }
  getAttendanceByBatchIdAndSkillName(skillName, batchId, token) {
    return axios.get(
      variable.TUTORIAL_API_BATCHMANAGEMENT_URL +
      "attendance/" +
      batchId +
      "/" +
      skillName,
      {
        headers: {
          Authorization: token,
        },
      }
    );
  }
  getResult(userId, subCourceId, batchId) {
    return axios.get(
      variable.TUTORIAL_API_Exam_URL +
      "getResult/" +
      userId +
      "/" +
      subCourceId +
      "/" +
      batchId
    );
  }
  getSingleUserResult(token, userId) {
    return axios.get(
      variable.TUTORIAL_API_Exam_URL + "getSingleUserResult/" + userId,
      {
        headers: {
          Authorization: token,
        },
      }
    );
  }
  getResultbycourse(subCourceId, batchId) {
    return axios.get(
      variable.TUTORIAL_API_Exam_URL +
      "getResultbycourse/" +
      subCourceId +
      "/" +
      batchId
    );
  }

  //add day in batch

  getUpdateResult(examcode) {
    return axios.put(
      variable.TUTORIAL_API_Exam_URL + "updateResult/" + examcode
    );
  }

  postQuestion(cid, qtype, tplans, token) {
    return axios.post(
      variable.TUTORIAL_API_Exam_URL + cid + "/" + qtype + "/addQuestion",
      tplans,
      {
        headers: {
          Authorization: token,
        },
      }
    );
  }

  editQuestions(qid, qtype, tplans, token) {
    return axios.put(
      variable.TUTORIAL_API_Exam_URL + qid + "/" + qtype + "/editQuestion",
      tplans,
      {
        headers: {
          Authorization: token,
        },
      }
    );
  }
  changeQuestionsRights(qid, qtype, right, token) {
    return axios.put(
      variable.TUTORIAL_API_Exam_URL +
      "changequestionstatus/" +
      qid +
      "/" +
      qtype +
      "/" +
      right,
      {}
      ,
      {
        headers: {
          Authorization: token,
        },
      }
    );
  }

  deleteQuestion(qid, qtype, token) {
    return axios.put(
      variable.TUTORIAL_API_Exam_URL + qid + "/" + qtype + "/deleteQuestion",
      qid,
      {
        headers: {
          Authorization: token,
        },
      }
    );
  }
  getSpecificResult(subCourceId, batchId) {
    return axios.get(
      variable.TUTORIAL_API_Exam_URL +
      "getResult/" +
      subCourceId +
      "/" +
      batchId
    );
  }
  getSpecificResultBySkillName(subCourceName, batchId) {
    return axios.get(
      variable.TUTORIAL_API_Exam_URL +
      "getResultBySkillName/" +
      subCourceName +
      "/" +
      batchId
    );
  }

  getResultbybatch(batchId) {
    return axios.get(
      variable.TUTORIAL_API_Exam_URL + "resultbybatch/" + batchId
    );
  }
  changePracticalExamMark(examType, examData, token) {
    return axios.put(
      variable.TUTORIAL_API_Exam_URL + "changePracticalMark/" + examType,
      examData,
      {
        headers: {
          Authorization: token,
        },
      }
    );
  }
  deletePracticalExamMark(examType, addMarkList, deleteMarkId, token) {
    return axios.put(
      variable.TUTORIAL_API_Exam_URL +
      "delete-practical-mark/" +
      examType +
      "/" +
      deleteMarkId,
      addMarkList,
      {
        headers: {
          Authorization: token,
        },
      }
    );
  }

  getStudentColleagueProgressedCourses(token, userId, limit) {
    return axios.get(
      `${variable.TUTORIAL_API_TRAINING_URL}get-progressed-courses/${userId}`,
      {
        headers: {
          Authorization: token,
        },
        params: {
          limit,
        },
      }
    );
  }
}

export default new CourseService();
