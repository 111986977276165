import { React, useState, useEffect, createContext } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import $ from "jquery";

import "../App.scss";

// Importing Pages
import Home from "../pages/home/Home";
import Signup from "../pages/login/Signup";
import Static from "../pages/static/static";
import ForgotPassword from "../pages/forgot/ForgotPassword";
import ResetPassword from "../pages/reset/ResetPassword";
import CorrectAnswer from "../pages/correctanswers/CorrectAnswer";
import CourseDetails from "../pages/courseDetails/courseDetails";
import ExploreCourses from "../pages/exploreCourses/exploreCourses";
import SubCourse from "../pages/course/SubCourse";
import Description from "../pages/description/Description";
import Profile from "../components/userdashboard/UserProfile";
import Cloudvideo from "../pages/course/Cloudvideo";
import CloudVideom3u8 from "../pages/course/CloudVideom3u8";
import Videom3u8 from "../pages/course/Videom3u8";
import AccessRequest from "../pages/login/AccessRequest";
import MyLearning from "../pages/myLearning/MyLearning";
import Leaves from "../pages/myLeaves/Leave";
import CourseOverview from "../pages/courseOverview/courseOverview";
import CourseList from "../pages/courseList/courseList";
import VideoTutorial from "../components/videoTutorial/VideoTutorial";
import Calendar from "../components/calendar/Calendar";
import ExamPage from "../pages/exampage/Exam";
import McqExam from "../pages/exampage/McqExam";
import ExamVideo from "../pages/exampage/ExamVideo";
import PracticalExam from "../pages/exampage/PracticalExam";
import BatchInfo from "../pages/batchInfo/BatchInfo";
import Excel from "../components/Excel";
import RouteAuthGuard from "./RouteAuthGuard";
import Dashboard from "../components/dashboard/Dashboard";
import ManagePlan from "../components/forms/ManageBatch";
import ManageTrainingPDF from "../components/forms/ManageTrainingPDF";
import ManageTrainingPlan from "../components/forms/ManageTrainingPlan";
import Managem3u8video from "../components/forms/ManageM3U8videos";
import ManageSubscription from "../components/forms/ManageSubscription";
import ManageApproval from "../components/forms/ManageApproval";
import ManageCategory from "../components/category/ManageCategory";
import UpdateTrainingPlanRequest from "../components/forms/UpdateTrainingPlanRequest";
import CreateExamSchedule from "../components/forms/CreateExamSchedule";
import UpdateContentRequests from "../components/forms/UpdateContentRequests";
import ManageTrainingProgress from "../components/forms/ManageTrainingProgress";
import ParentBatch from "../components/department/parent-batch/ParentBatch";
import ResultBatchParentNew from "../components/forms/ResultBatchParentNew";
import UpdateResultRequest from "../components/forms/UpdateResult";
import CreateBatch from "../components/forms/CreateBatch";
import AddDayInBatch from "../components/forms/AddDayInBatch";
import ManagePracticalQuestionList from "../components/forms/ManagePracticalQuestionList";
import ManageMCQQuestionList from "../components/forms/ManageMCQQuestionList";
import Admin1 from "../pages/admin/Admin";
import Error from "../pages/error/error";
import Login from "../pages/login/Login";
import Navbar from "../components/navbar/Navbar";
import Footer from "../components/footer/Footer";
import UpdateUserUsingExcel from "../components/user/UpdateUserUsingExcel";
import AddUser from "../components/user/AddUser";
import UpdateUser from "../components/user/UpdateUser";
import ManageUser from "../components/user/ManageUser";
import CoursesList from "../components/userAnalysis/CoursesList";
import ReviewChart from "../components/userAnalysis/ReviewChart";
import ManageCourses from "../components/course/ManageCourses";
import AddCourse from "../components/course/AddCourse";
import AddCategory from "../components/category/AddCategory";
import CoursePPT from "../components/ppt/CoursePPT";
import VideoList from "../components/videos/VideoList";
import AddTrainingPlan from "../components/forms/AddTrininigPlan";
import RemoveTrainingPlan from "../components/forms/RemoveTrainingPlan";
import CloneTrainingPlan from "../components/forms/CloneTrainingPlan";
import UpdateTrainingPlan from "../components/forms/UpdateTrainingPlan";
import UserList from "../components/videoProgress/UserList";
import UserVideoProgress from "../components/videoProgress/UserVideoProgress";
import SubCourseList from "../components/subCourse/SubCourseList";
import AddUpdateSubCourse from "../components/subCourse/AddUpdateSubCourse";
import BatchList from "../components/manageBatch/BatchList";
import UpdateSkillDaysAndWeightage from "../components/manageBatch/UpdateSkillDaysAndWeightage";
import UpdateBatch from "../components/manageBatch/UpdateBatch";
import ManageQuestion from "../components/forms/ManageQuestion";
import CategoryList from "../components/productandcategory/category/CategoryListing";
import CreateCategory from "../components/productandcategory/category/CreateCategory";
import CreateProduct from "../components/productandcategory/product/CreateProduct";
import ProductList from "../components/productandcategory/product/ProductList";
import CreateCourses from "../components/course-list/Create-Course";
import CoureseLists from "../components/course-list/CourseList";
import StudentList from "../components/Student-List/StudentList";
import CreateOrUpdateStudent from "../components/Student-List/CreateStudent"; import BatchLists from "../components/batch/BatchLists";
import AddBatch from "../components/batch/AddBatch";
import CategoryDetails from "../components/productandcategory/category/CategoryDetail";
import ProductDetails from "../components/productandcategory/product/ProductDetails";
import ContactUs from "../components/Contactus/ContactUs";
import InfutFile from "../components/inputFile/InputFile"
import AllCourseDetails from "../components/course-list/CourseDetails";
import StudentDetails from "../components/Student-List/StudentDetails";
import BatchDetails from "../components/batch/BatchDetails";
import UploadDocument from "../components/documents/UploadDocument";
import ShowContactForm from "../components/showcontactform/ShowContactForm";

export const UserContext = createContext({});

// Importing components
const Routing = () => {
  const [loginStatus, setLoginStatus] = useState(false);
  const [view, setView] = useState("");
  console.warn = console.error = () => { };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setLoginStatus(true);
    } else {
      setLoginStatus(false);
    }
    var headerHeight = $(".navbar").outerHeight();
    var footerHeight = $("footer").outerHeight();
    var footerCopyright = $(".footer-lg").outerHeight();
    var bannerHeight = 0;
    var totalHeight =
      headerHeight + bannerHeight + footerHeight + footerCopyright;
    $(".page-main").css({ "margin-top": headerHeight });
    $(".page-main").css({ "min-height": "calc(100vh - " + totalHeight + "px" });
  }, [setView]);
  return (
    <UserContext.Provider
      value={{ loginStatus, setLoginStatus, setView, view }}
    >
      <Router>
        <Navbar />
        <div class="page-main bg-page-bg">
          <Routes>
            {/* Public Routes */}
            <Route path="/" element={<Home />} />
            <Route
              path="correctanswer/:examId/:userId/:examType"
              element={<CorrectAnswer />}
            />
            <Route path="static" element={<Static />} />
            <Route path="login" element={<Login />} />
            <Route path="signup" element={<Signup />} />
            <Route path="forgot-password" element={<ForgotPassword />} />
            <Route
              path="reset-password/:resetoken"
              element={<ResetPassword />}
            />

            <Route
              path="course/:cname/courseDetails/:sname"
              element={<CourseDetails />}
            />
            <Route path="exploreCourses" element={<ExploreCourses />} />

            {/* Student Route */}
            <Route
              path="batchinfo/:SkillName/:ExamType/:BatchId"
              element={
                <RouteAuthGuard component={<BatchInfo />} redirect="/" />
              }
            />

            <Route
              path="calendar"
              element={
                <RouteAuthGuard
                  component={
                    <Calendar
                      setLoginStatus={setLoginStatus}
                      loginStatus={loginStatus}
                    />
                  }
                  redirect="/"
                />
              }
            />
            {/* <Route
              path="usersResults"
              element={
                <RouteAuthGuard
                  component={
                    <ResultBatchParentNew
                      setLoginStatus={setLoginStatus}
                      loginStatus={loginStatus}
                    />
                  }
                  redirect="/"
                />
              }
            /> */}
            <Route
              path="profile"
              element={
                <RouteAuthGuard
                  component={
                    <Profile
                      setLoginStatus={setLoginStatus}
                      loginStatus={loginStatus}
                    />
                  }
                  redirect="/"
                />
              }
            />
            <Route
              path="mylearning"
              element={
                <RouteAuthGuard component={<MyLearning />} redirect="/" />
              }
            />
            {/* <Route
              path="applyleave"
              element={<RouteAuthGuard component={<Leaves />} redirect="/" />}
            /> */}

            <Route
              path="course/:cname/courseOverview/:sname/:choice/:vid"
              element={
                <RouteAuthGuard component={<VideoTutorial />} redirect="/" />
              }
            />
            <Route
              path="course/:cname/courseOverview/:sname/:choice"
              element={
                <RouteAuthGuard component={<CourseOverview />} redirect="/" />
              }
            />
            <Route
              path="ExamVideo/:batchName/:skillName/:examCode/:hostId/:userId"
              element={
                <RouteAuthGuard component={<ExamVideo />} redirect="/" />
              }
            />

            <Route
              path="course/:cname"
              element={
                <RouteAuthGuard
                  component={
                    <CourseList
                      setLoginStatus={setLoginStatus}
                      loginStatus={loginStatus}
                    />
                  }
                  redirect="/login"
                />
              }
            />
            <Route
              path="testExam"
              element={
                <RouteAuthGuard
                  component={
                    <ExamPage
                      setLoginStatus={setLoginStatus}
                      loginStatus={loginStatus}
                    />
                  }
                  redirect="/"
                />
              }
            />
            <Route
              path="MCQtestExam/:examId"
              element={
                <RouteAuthGuard
                  component={
                    <McqExam
                      setLoginStatus={setLoginStatus}
                      loginStatus={loginStatus}
                    />
                  }
                  redirect="/"
                />
              }
            />

            <Route
              path="practicalExam"
              element={
                <RouteAuthGuard
                  component={
                    <PracticalExam
                      setLoginStatus={setLoginStatus}
                      loginStatus={loginStatus}
                    />
                  }
                  redirect="/"
                />
              }
            />

            <Route
              path="course/:cname/subcourse/:sname"
              element={
                <RouteAuthGuard
                  component={
                    <SubCourse
                      setLoginStatus={setLoginStatus}
                      loginStatus={loginStatus}
                    />
                  }
                  redirect="/"
                />
              }
            />

            <Route
              path="description/:sname"
              element={
                <RouteAuthGuard
                  component={
                    <Description
                      setLoginStatus={setLoginStatus}
                      loginStatus={loginStatus}
                    />
                  }
                  redirect="/"
                />
              }
            />

            <Route
              path="access-request/:token"
              element={
                <RouteAuthGuard component={<AccessRequest />} redirect="/" />
              }
            />

            <Route
              path="cloudedemoplayer"
              element={
                <RouteAuthGuard
                  component={
                    <Cloudvideo
                      setLoginStatus={setLoginStatus}
                      loginStatus={loginStatus}
                    />
                  }
                  redirect="/"
                />
              }
            />

            <Route
              path="cloudedemoplayerm3u8"
              element={
                <RouteAuthGuard
                  component={
                    <CloudVideom3u8
                      setLoginStatus={setLoginStatus}
                      loginStatus={loginStatus}
                    />
                  }
                  redirect="/"
                />
              }
            />
            <Route
              path="demoplayerm3u8"
              element={
                <RouteAuthGuard
                  component={
                    <Videom3u8
                      setLoginStatus={setLoginStatus}
                      loginStatus={loginStatus}
                    />
                  }
                  redirect="/"
                />
              }
            />

            <Route
              path="excel"
              element={<RouteAuthGuard component={<Excel />} redirect="/" />}
            />

            <Route
              path="admin"
            >

              {/* Vulnearablities- Course-List */}
              <Route
                path="course-list"
                element={<CoureseLists />}
              /
              >

              <Route
                path="create-course"
                element={<CreateCourses />}
              />

              <Route
                path="update-course/:id"
                element={<CreateCourses />}
              />

              <Route path="coursedetails"
                element={<AllCourseDetails />}
              ></Route>

              {/* Vulnearablities- Student-List */}
              <Route
                path="student-list"
                element={<StudentList />}
              /
              >

              <Route
                path="create-student"
                element={<CreateOrUpdateStudent />}
              />

              <Route
                path="update-student/:id"
                element={<CreateOrUpdateStudent />}
              />
              <Route
                path="studentdetails"
                element={<StudentDetails />}
              />

              {/* Batch routing */}
              <Route
                path="Batch/list"
                element={<BatchLists />}
              />

              <Route
                path="createBatch"
                element={<AddBatch />}
              />
              <Route
                path="updateBatch/:id"
                element={<AddBatch />}
              />

              <Route
                path="batchdetails"
                element={<BatchDetails />}
              />

              <Route
                path="documents"
                element={<UploadDocument />}
              ></Route>

              <Route
                path="contact-form"
                element={<ShowContactForm />}
              />
            </Route>

            {/* Admin Routes */}
            <Route
              path="admin"
              element={
                <RouteAuthGuard
                  component={<Admin1 />}
                  redirect="/"
                  isAdmin={true}
                />
              }
            >
              <Route path="dashboard" element={<Dashboard />} />

              {/* User Management Routes */}
              <Route path="user/manage" element={<ManageUser />}>
                {/* child routing */}
                <Route path="add" element={<AddUser />} />
                <Route
                  path="update-with-excel"
                  element={<UpdateUserUsingExcel />}
                />
                <Route path="update/:id" element={<UpdateUser />} />
              </Route>
              <Route
                path="user/subscription"
                element={<ManageSubscription />}
              />
              <Route path="user/approval" element={<ManageApproval />} />
              <Route
                path="user/training-progress"
                element={<ManageTrainingProgress />}
              />
              <Route
                path="user/manage-user-analysis"
                element={<CoursesList title="Courses" />}
              />
              <Route
                path="user/manage-user-analysis/courses/:id"
                element={<ReviewChart />}
              />
              <Route path="user/video-progress" element={<UserList />} />
              <Route
                path="user/:userId/video-progress"
                element={<UserVideoProgress />}
              />

              {/* Course Routes */}
              <Route path="course/manage-course" element={<ManageCourses />} />
              <Route path="course/manage-course/add" element={<AddCourse />} />
              <Route
                path="course/manage-category"
                element={<ManageCategory />}
              />
              <Route
                path="course/manage-category/add"
                element={<AddCategory />}
              />
              <Route
                path="course/manage-subcourse"
                element={<SubCourseList />}
              />
              <Route
                path="course/manage-subcourse/add"
                element={<AddUpdateSubCourse />}
              />
              <Route
                path="course/manage-subcourse/edit/:id"
                element={<AddUpdateSubCourse />}
              />
              <Route path="course/manage-plan" element={<ManagePlan />} />
              <Route
                path="course/update-training-plan-request"
                element={<UpdateTrainingPlanRequest />}
              />
              {/* <Route
                path="course/update-content-request"
                element={<UpdateContentRequests />}
              /> */}
              <Route path="course/ppt" element={<CoursesList title="PPTs" />} />
              <Route path="course/:id/ppt" element={<CoursePPT />} />
              <Route path="course/m3u8video" element={<Managem3u8video />} />
              <Route
                path="course/video"
                element={<CoursesList title="Videos" />}
              />
              <Route path="course/:id/video" element={<VideoList />} />
              <Route
                path="course/training-plan"
                element={<ManageTrainingPlan />}
              >
                <Route path="add" element={<AddTrainingPlan />} />
                <Route path="update" element={<UpdateTrainingPlan />} />
                <Route path="remove" element={<RemoveTrainingPlan />} />
                <Route path="clone" element={<CloneTrainingPlan />} />
              </Route>

              <Route path="course/manage-pdf" element={<ManageTrainingPDF />} />

              {/* Test Routes */}
              <Route
                path="test/manage-questions"
                element={<ManageQuestion />}
              />
              <Route path="test/create-exam" element={<CreateExamSchedule />} />
              <Route
                path="test/manage-practical-questions"
                element={<ManagePracticalQuestionList />}
              />
              <Route
                path="test/manage-mcq-questions"
                element={<ManageMCQQuestionList />}
              />

              {/* Batch Manangement Routes */}
              <Route path="batch-management/create" element={<CreateBatch />} />
              <Route path="batch-management/manage" element={<BatchList />} />
              <Route
                path="batch-management/manage/:id/update-skill-days"
                element={<UpdateSkillDaysAndWeightage />}
              />
              <Route
                path="batch-management/manage/:id/update"
                element={<UpdateBatch />}
              />
              <Route
                path="batch-management/add-day"
                element={<AddDayInBatch />}
              />
              <Route
                path="batch-management/add-parent-batch"
                element={<ParentBatch />}
              />
              <Route
                path="result/update-request"
                element={<UpdateResultRequest />}
              />



            </Route>


            <Route
              path="/productcategory/list"
              element={<CategoryList />}
            />

            <Route
              path="/createCategory"
              element={<CreateCategory />}
            />

            <Route
              path="/categorydetails"
              element={<CategoryDetails />}
            />
            <Route
              path="/updatecategory/:id"
              element={<CreateCategory />}
            />

            <Route
              path="/createProduct"
              element={<CreateProduct />}
            />

            <Route
              path="/updateproduct/:id"
              element={<CreateProduct />}
            />

            <Route
              path="/productlisting"
              element={<ProductList />}
            />
            <Route
              path="/productdetails"
              element={<ProductDetails />}
            />

            <Route
              path="/contact-us"
              element={<ContactUs />}
            />

            <Route
              path="/managetextdata"
              element={<InfutFile />}
            />

            <Route path="*" element={<Error />} />
          </Routes>
        </div>
        <Footer />
      </Router>
    </UserContext.Provider >
  );
};

export default Routing;
