import React, { useEffect, useState, useRef } from "react";
import CourseService from "../../services/CourseService";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { useWindowWidth } from "@wojtekmaj/react-hooks";
import CourseTab from "../../components/courseTab/courseTab";
import { Document, Page, pdfjs } from "react-pdf";
import global from "../../servicesvariable";
import { fileURL } from "../../services/CourseService";
import Reacthlsplayer from "react-hls-player";
import datanotfoundimage from "../../assets/images/DataNotFound-image.png";
import jwt_decode from "jwt-decode";
import formloader from "../../images/formloading.svg";

import $ from "jquery";
import "./VideoTutorial.scss";
export default function VideoTutorial(props) {
  const { course, videoss, abc, setAbc } = props;
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  const scname = props.scname;
  const navigate = useNavigate();
  const location = useLocation();
  let { cname, sname, vid } = useParams();
  const param = useParams();
  const video = useRef();
  const [numPages, setNumPages] = useState(null);
  const [videos, setVideos] = useState([]);
  const [playvideo, setPlayvideo] = useState();
  const width = useWindowWidth();
  const [playvideoid, setPlayvideoID] = useState();
  const [played, setPlayed] = useState();
  const [percentages, setPercentages] = useState([]);
  const [currentplayTime, setplayTime] = useState(0);
  const [percents, setPercent] = useState(0);
  const [index, setindex] = useState(0);
  const [sidelg, setSideLG] = useState(true);
  const [sidesm, setSideSM] = useState(false);
  const [loading, setLoading] = useState(false);
  const [videoQuality, setVidoQuality] = useState(".m3u8");
  const token = localStorage.getItem("token");
  const searchRegExp = /\s/g;
  var lastvideo = [];
  if (props.percentages) {
    lastvideo = props.percentages;
  } else {
    lastvideo = location.state;
  }
  var refresh = window.localStorage.getItem("refresh");
  if (refresh !== null) {
    window.localStorage.removeItem("refresh");
  }
  $(document).ready(function () {
    var headerHeight = $("header").height();
    var bannerHeight = $(".tutorial-banner").height();
    var footerHeight = $("footer").height();
    var totalHeight = headerHeight + bannerHeight + footerHeight;
    if ($(window).width() > 1025) {
      $(".sidebar").css("height", "calc(99vh - " + totalHeight + "px - 90px");
      $(".video-tutorial").css(
        "height",
        "calc(99vh - " + totalHeight + "px - 50px"
      );
      sidebarlg();
      videoPosition();
      setSideSM(sidesm === true ? false : false);
    } else {
      sidebarsm();
      setSideLG(sidelg === true ? false : false);
    }
    $(window).scroll(function (e) {
      topbarSticky();
      sideBarSticky();
    });
    $(window).resize(function (e) {
      if ($(window).width() > 1025) {
        $(".sidebar").css("height", "calc(99vh - " + totalHeight + "px - 90px");
        $(".video-tutorial").css(
          "height",
          "calc(99vh - " + totalHeight + "px - 50px"
        );
        $(".sidebar-wrapper").css({
          top: $("header").height() + $(".tutorial-banner").height() + 20,
        });
        $(".sidebar-wrapper").css(
          "height",
          "calc(99vh - " + totalHeight + "px - 90px"
        );

        sidebarlg();
        // videoPosition();
        setSideSM(false);
      } else {
        sidebarsm();
        setSideLG(sidelg === true ? false : false);
      }
      $(window).scroll(function (e) {
        topbarSticky();
        sideBarSticky();
      });
    });

    $(".sidebar-wrapper").css({
      top: $("header").height() + $(".tutorial-banner").height() + 50,
    });
  });
  function sidebarlg() {
    $(".collapse-btn").click(function () {
      setSideLG(false);
      $(".sidebar-wrapper").addClass("collapse-sidebar");
      $(".sidebar-wrapper").removeClass("col-lg-3 col-12  pt-4");
      $(".tab-contents").addClass("mx-auto");
    });
    $(".slider-right-btn").click(function () {
      setSideLG(true);
      $(".sidebar-wrapper").removeClass("collapse-sidebar");
      $(".sidebar-wrapper").addClass("col-lg-3 col-12  pt-4");
      $(".tab-contents").removeClass("mx-auto");
    });
  }
  function videoPosition() {
    $(window).scroll(function (e) {
      var headerHeight = $("header").height();
      var bannerHeight = $(".tutorial-banner").height();
      var footerHeight = $("footer").height();
      var totalHeight = headerHeight + bannerHeight + footerHeight;
      if ($(this).scrollTop() > 0) {
        if ($(this).scrollTop() > $(".video-tutorial").height() / 1.5) {
          $(".video-tutorial").addClass("video-fixed");
          $(".video-tutorial").css({ height: "17.25rem", width: "30.625rem" });
        }
      } else {
        $(".video-tutorial").removeClass("video-fixed");
        $(".video-tutorial").css(
          "height",
          "calc(99vh - " + totalHeight + "px - 50px"
        );
      }
    });
    if ($(window).scrollTop() > 0) {
      $(".video-tutorial").css({ height: "17.25rem", width: "30.625rem" });
    }
  }
  function sidebarsm() {
    $(".smsidebar").css("height", "99vh");
    $(".sidebar-wrapper").addClass("collapse-sidebar");
    $(".sidebar-wrapper").removeClass("col-lg-3 col-12  pt-4");
    $(".toggle-sidebar").click(function (e) {
      setSideSM(true);
      $(".sidebar-wrapper").removeClass("collapse-sidebar");
      $("body").addClass("overflow-hidden");
      $(".sidebar-bg").removeClass("d-none");
    });
    $(".close-sidebar").click(function (e) {
      setSideSM(false);
      $(".sidebar-wrapper").addClass("collapse-sidebar");
      $("body").removeClass("overflow-hidden");
      $(".sidebar-bg").addClass("d-none");
    });
  }
  function topbarSticky() {
    var $el = $(".tutorial-banner");
    if ($(window).scrollTop() > 50) {
      $("header").css({ height: "0px", overflow: "hidden" });
      $el.css({ top: "0px" });
      $el.addClass("position-sticky");
    } else {
      $el.removeClass("position-sticky");
      $("header").css({ height: "", overflow: "" });
    }
  }
  function sideBarSticky() {
    var headerHeight = $("header").height();
    var bannerHeight = $(".tutorial-banner").height();
    var footerHeight = $("footer").height();
    var totalHeight = headerHeight + bannerHeight + footerHeight;

    if ($(window).scrollTop() > 0) {
      $(".sidebar-wrapper").css({
        top: $("header").height() + $(".tutorial-banner").height() + 50,
      });
    } else {
      $(".sidebar-wrapper").css({
        top: $("header").height() + $(".tutorial-banner").height() + 50,
      });
    }
  }

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  function removeTextLayerOffset() {
    const textLayers = document.querySelectorAll(
      ".react-pdf__Page__textContent"
    );

    textLayers.forEach((layer) => {
      const { style } = layer;
      style.top = "0";
      style.left = "0";
      style.transform = "";
    });
  }

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (!token) {
      navigate("/login");
      setLoading(false);
    } else {
      var result = [];
      const uid = jwt_decode(token).id;

      CourseService.getVideos(sname)
        .then((res) => {
          if (res.data.length > 0) {
            const fileName = res.data[0].videos[0].filename;
            navigate(
              `/course/${param.cname}/courseOverview/${param.sname}/videos/${fileName}`
            );
            setVideos(res.data);
            result.push(res.data);
          }
        })
        .catch((ex) => console.log(ex));
    }
  }, []);
  useEffect(() => {
    setLoading(true);
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
      setLoading(false);
    } else {
      var result = [];
      const uid = jwt_decode(token).id;

      CourseService.getVideos(sname)
        .then((res) => {
          if (res.data.length > 0) {
            setVideos(res.data);
            result.push(res.data);
            setLoading(false);
          }
        })
        .catch((ex) => console.log(ex));
      CourseService.getProgress(token, uid)
        .then((res) => {
          const video = [];
          if (res.data.length > 0) {
            res.data.map((res1) => {
              if (res1.cname == param.cname) {
                video.push(res1);
              }
            });
            // if (param.choice == "videos") {
            //   result[0].map((data) => {
            //     return data.videos.map((item) => {
            //       if (item.id == video[video.length - 1].id) {
            //         navigate(
            //           `/course/${cname}/courseOverview/${sname}/videos/${item.filename}`
            //         );
            //       }
            //     });
            //   });
            // }
            setPercentages(video);
          }
          setLoading(false);
        })
        .catch((ex) => console.log(ex));
    }
  }, [vid]);

  const openNav = () => {
    document.getElementById("myNav").style.width = "100%";
    document.getElementById("playvideos").style.display = "none";
  };

  const closeNav = () => {
    document.getElementById("myNav").style.width = "0%";
    document.getElementById("playvideos").style.display = "block";
  };

  const handlePlayVideo = async (url) => {
    navigate(
      "/course/" +
        cname +
        "/courseOverview/" +
        sname +
        "/videos/" +
        url.filename,
      props.percentages
    );
    const token = localStorage.getItem("token");
    const vid = url.filename;

    if (played) {
      const element = percentages.find((element) => {
        return element.id === played.id;
      });
      if (!element && token) {
        const decoded = jwt_decode(token);
        const data = {
          id: played.id,
          uid: decoded.id,
          cname: sname,
          percentage: Math.round(
            (video.current.currentTime * 100) / video.current.duration
          ),
        };
        // CourseService.postProgress(data, token, decoded.id).then((item) => { });
      }
    } else if (!param.vid && lastvideo[0]) {
      const element = percentages.find((element) => {
        return element.id === lastvideo[0].id;
      });
      if (!element && token) {
        const decoded = jwt_decode(token);
        const data = {
          id: lastvideo[0].id,
          uid: decoded.id,
          cname: sname,
          percentage: Math.round(
            (video.current.currentTime * 100) / video.current.duration
          ),
        };
        // CourseService.postProgress(data, token, decoded.id).then((item) => { });
      }
    } else {
      const element = percentages.find((element) => {
        return element.id === videos[0].videos[0].id;
      });
      if (!element && token) {
        const decoded = jwt_decode(token);
        const data = {
          id: videos[0].videos[0].id,
          uid: decoded.id,
          cname: sname,
          percentage: Math.round(
            (video.current.currentTime * 100) / video.current.duration
          ),
        };
        // CourseService.postProgress(data, token, decoded.id).then((item) => { });
      }
    }
    var element = [];
    if (played) {
      element = percentages.find((element) => {
        return element.id === played.id;
      });
    } else if (!played && lastvideo && lastvideo.length > 0) {
      element = percentages.find((element) => {
        return element.id === lastvideo[0].id;
      });
    } else if (!played && !lastvideo && videos[0]) {
      element = percentages.find((element) => {
        return element.id === videos[0].videos[0].id;
      });
    }
    if (
      percents !== 100 &&
      percents !== 0 &&
      element &&
      element.percentage !== 100
      // video.current &&
      // video.current.currentTime
    ) {
      const per = {
        percentage: Math.round(
          (video.current.currentTime * 100) / video.current.duration
        ),
      };
      if (played && token) {
        const decoded = jwt_decode(token);
        CourseService.putProgress(played.id, per, token, decoded.id).then(
          async (item) => {
            // percentages.forEach(async (element) => {
            //   if (element.id === url.id) {
            //     await setPercent(element.percentage);
            //   }
            // });
            // setPercent(0);
          }
        );
      } else if (lastvideo && lastvideo.length > 0) {
        const decoded = jwt_decode(token);
        CourseService.putProgress(lastvideo[0].id, per, token, decoded.id).then(
          async (item) => {}
        );
      } else {
        const decoded = jwt_decode(token);
        CourseService.putProgress(
          videos[0].videos[0].id,
          per,
          token,
          decoded.id
        ).then(async (item) => {});
      }
    }

    setPlayvideo(url.filename);
    setPlayvideoID(url.id);
    percentages.forEach(async (element) => {
      if (element.id === url.id) {
        await setPercent(element.percentage);
      }
    });
    $(document).ready(function () {
      if ($(window).width() < 1025) {
        setSideSM(false);
        $(".sidebar-wrapper").addClass("collapse-sidebar");
        $("body").removeClass("overflow-hidden");
        $(".sidebar-bg").addClass("d-none");
      }
    });
  };

  const handleplay = async () => {
    if (percents !== 0 && percents !== 100) {
      video.current.currentTime = (video.current.duration * percents) / 100;
    }
  };

  const handlepause = async () => {
    if (Math.ceil((video.current.currentTime * 100) / video.current.duration)) {
      await setplayTime(Math.floor(video.current.currentTime));
      const per = {
        percentage: Math.ceil(
          (video.current.currentTime * 100) / video.current.duration
        ),
      };
      if (
        Math.floor(
          (video.current.currentTime * 100) / video.current.duration
        ) === 100
      ) {
        videos.forEach((item) => {
          item.videos.forEach(async (video) => {
            if (played) {
              if (video.id === played.id) {
                await setPlayed(item.videos[index + 1]);
                await setindex(index + 1);
                percentages.forEach(async (element) => {
                  if (item.videos[index + 1]) {
                    if (element.id === item.videos[index + 1].id) {
                      await setPercent(element.percentage);
                    }
                  }
                });
                if (played.percentage && played.percentage === 100) {
                }

                if (item.videos[index + 1]) {
                  await handlePlayVideo(item.videos[index + 1]);
                }
              }
            } else {
              if (video.id === videos[0].videos[0].id) {
                await setPlayed(item.videos[index + 1]);
                await setindex(index + 1);
                percentages.forEach(async (element) => {
                  if (item.videos[index + 1]) {
                    if (element.id === item.videos[index + 1].id) {
                      await setPercent(element.percentage);
                    }
                  }
                });
                if (item.videos[index + 1]) {
                  await handlePlayVideo(item.videos[index + 1]);
                }
              }
            }
          });
        });
      }
      if (per.percentage) {
        await setPercent(per.percentage);
      }
    }
  };

  const handlePdf = () => {
    return Array.apply(null, { length: numPages })
      .map((val, index) => {
        return index + 1;
      })
      .map((pages, i) => (
        <div id={`page-${pages}`} key={i}>
          {width < 1200 && (
            <Page
              onLoadSuccess={removeTextLayerOffset}
              renderAnnotationLayer={false}
              renderTextLayer={true}
              pageNumber={pages}
              width={Math.min(width * 0.9, 400)}
            />
          )}
          {width >= 1200 && (
            <Page
              onLoadSuccess={removeTextLayerOffset}
              renderAnnotationLayer={false}
              renderTextLayer={true}
              pageNumber={pages}
            />
          )}
        </div>
      ));
  };

  const handleVideoLink = (vid) => {
    if (vid?.split("-")?.[0]?.includes("new")) {
      return global.newVideoUrl + "/" + vid.replace(".m3u8", videoQuality);
    } else {
      return global.SERVER + "/" + vid;
    }
  };

  if (videos[0] || course) {
    if (videos[0]) {
      return (
        <div className="container-fluid">
          {loading && (
            <div className="formloader">
              <div className="row text-center">
                <div className="col-12">
                  <img src={formloader} alt="" height="100" />
                </div>
                <div className="col-12 text-white h4">Loading...</div>
              </div>
            </div>
          )}
          <div className="row">
            <div class="col-12 tutorial-banner pb-0 pb-lg-3">
              <CourseTab
                videos={videos}
                setSideSM={setSideSM}
                sidesm={sidesm}
                setSideLG={setSideLG}
                sidelg={sidelg}
                videoQuality={videoQuality}
                setVidoQuality={setVidoQuality}
                lastvideos={
                  videos[0].videos[0]
                    ? videos.map((video) => {
                        if (video !== undefined) {
                          return video.videos.find((vids) => {
                            if (vids.filename == param.vid) {
                              return vids;
                            }
                          });
                        }
                      })[0] == undefined
                      ? videos.map((video) => {
                          if (video !== undefined) {
                            return video.videos.find((vids) => {
                              if (vids.filename == param.vid) {
                                return vids;
                              }
                            });
                          }
                        })[1]
                      : videos.map((video) => {
                          if (video !== undefined) {
                            return video.videos.find((vids) => {
                              if (vids.filename == param.vid) {
                                return vids;
                              }
                            });
                          }
                        })[0]
                    : ""
                }
              />
            </div>
            <div class="sidebar-bg d-none">
              <button
                type="button"
                class="btn-close btn-close-white close-sidebar me-0 d-xl-none d-block"
                aria-label="Close"
              ></button>
            </div>
            <div class="col-12 col-xl-3 col-lg-4 sidebar-wrapper">
              {sidelg == true && (
                <div className={`sidebar px-4 pb-4 sidebar-module`}>
                  <div
                    class="accordion-content course-detail-accordion"
                    id="accordionExample"
                  >
                    {videos &&
                      videos.map((video, index) => {
                        return (
                          <>
                            <div class="accordion-item">
                              <h3
                                class="accordion-header"
                                id={"heading" + (index + 1)}
                              >
                                <button
                                  class="accordion-button"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target={"#collapse" + (index + 1)}
                                  aria-expanded={
                                    index + 1 == 1 ? "true" : "false"
                                  }
                                  aria-controls={"collapse" + (index + 1)}
                                >
                                  {video.topic
                                    .replaceAll("[", "")
                                    .replaceAll("]", "")
                                    .replaceAll("_", "")
                                    .replaceAll("-", "")
                                    .replaceAll("&", "")
                                    .replaceAll("+", "")}
                                </button>
                              </h3>
                              <div
                                id={"collapse" + (index + 1)}
                                class={`accordion-collapse collapse ${
                                  index + 1 == 1 ? "show" : "hide"
                                }`}
                                aria-labelledby={"heading" + (index + 1)}
                                data-bs-parent="#accordionExample"
                              >
                                <div class="accordion-body">
                                  <ul>
                                    {video.videos.map((vid, index2) => {
                                      return (
                                        <li
                                          onClick={(e) => {
                                            handlePlayVideo(vid);
                                            setindex(index2);
                                            setPlayed(vid);
                                            setVidoQuality(".m3u8");
                                          }}
                                        >
                                          <p
                                            className={
                                              param.vid === vid.filename
                                                ? "text-primary"
                                                : !param.vid
                                                ? lastvideo[0]
                                                  ? lastvideo[0].id === vid.id
                                                    ? "text-primary"
                                                    : ""
                                                  : ""
                                                : ""
                                            }
                                          >
                                            {" "}
                                            {vid.filename
                                              .split("-")
                                              .slice(1, 5)
                                              .join(" ")
                                              .replace(".m3u8", "")
                                              .replace(".ir", "")
                                              .replace(".ir_", "")
                                              // .replace(/[^\D]/g, "")
                                              .replace(/^_/g, " ")
                                              // .replace(/^./g, " ")
                                              .replace(/^[a-zA-Z]+_/g, "")
                                              .replaceAll("_", " ")
                                              .replaceAll(".", " ")
                                              .replaceAll("[", " ")
                                              .replaceAll("]", " ")
                                              .replaceAll("&", "")
                                              .replaceAll("+", "")}{" "}
                                          </p>
                                        </li>
                                      );
                                    })}
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      })}
                  </div>
                </div>
              )}
              {sidesm == true && (
                <div class={`sidebar px-0 pb-4 smsidebar sidebar-module`}>
                  <div
                    class="accordion-content course-detail-accordion"
                    id="accordionExample"
                  >
                    {videos &&
                      videos.map((video, index) => {
                        return (
                          <div class="accordion-item">
                            <h3
                              class="accordion-header"
                              id={"heading" + (index + 1)}
                            >
                              <button
                                class="accordion-button"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target={"#collapse" + (index + 1)}
                                aria-expanded="true"
                                aria-controls={"collapse" + (index + 1)}
                              >
                                {video.topic
                                  .replaceAll("[", "")
                                  .replaceAll("]", "")
                                  .replaceAll("_", "")
                                  .replaceAll("-", "")
                                  .replaceAll("&", "")
                                  .replaceAll("+", "")}
                              </button>
                            </h3>
                            <div
                              id={"collapse" + (index + 1)}
                              class={`accordion-collapse collapse ${
                                index + 1 == 1 ? "show" : "hide"
                              }`}
                              aria-labelledby={"heading" + (index + 1)}
                              data-bs-parent="#accordionExample"
                            >
                              <div class="accordion-body">
                                <ul>
                                  {video.videos.map((vid, index2) => {
                                    return (
                                      <li
                                        className="close-side"
                                        onClick={(e) => {
                                          handlePlayVideo(vid);
                                          setindex(index2);
                                          setPlayed(vid);
                                          // setSideSM(false);
                                        }}
                                      >
                                        <p
                                          className={
                                            param.vid === vid.filename
                                              ? "text-primary"
                                              : !param.vid
                                              ? lastvideo[0]
                                                ? lastvideo[0].id === vid.id
                                                  ? "text-primary"
                                                  : ""
                                                : ""
                                              : ""
                                          }
                                        >
                                          {" "}
                                          {vid.filename
                                            .split("-")
                                            .slice(1, 5)
                                            .join(" ")
                                            .replace(".m3u8", "")
                                            .replace(".ir", "")
                                            .replace(".ir_", "")
                                            // .replace(/[^\D]/g, "")
                                            .replace(/^_/g, " ")
                                            // .replace(/^./g, " ")
                                            .replace(/^[a-zA-Z]+_/g, "")
                                            .replaceAll("-", " ")
                                            .replaceAll("_", " ")
                                            .replaceAll(".", " ")
                                            .replaceAll("[", " ")
                                            .replaceAll("]", " ")
                                            .replaceAll("&", "")
                                            .replaceAll("+", "")}{" "}
                                        </p>
                                      </li>
                                    );
                                  })}
                                </ul>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              )}
            </div>

            <div class="px-xl-4 col-12 col-xl-9 col-lg-8 tab-contents sidebar-course-content pt-4">
              <div class="embed-responsive embed-responsive-16by9 mb-lg-4">
                {played && !lastvideo && played.filename && !vid && (
                  <>
                    <Reacthlsplayer
                      id="video"
                      width="382"
                      height="192"
                      src={handleVideoLink(played.filename)}
                      class="video-tutorial"
                      onPlay={handleplay}
                      onPause={handlepause}
                      playerRef={video}
                      // onTimeUpdate={handleTime}
                      controls
                    ></Reacthlsplayer>
                  </>
                )}
                {vid && (
                  <>
                    <Reacthlsplayer
                      id="video"
                      width="382"
                      height="192"
                      src={handleVideoLink(vid)}
                      class="video-tutorial"
                      onPlay={handleplay}
                      onPause={handlepause}
                      playerRef={video}
                      // onTimeUpdate={handleTime}
                      controls
                    ></Reacthlsplayer>
                  </>
                )}
                {videos[0] &&
                  videos[0].videos[0] &&
                  !vid &&
                  !played &&
                  !lastvideo[0] && (
                    <>
                      <Reacthlsplayer
                        id="video"
                        width="382"
                        height="192"
                        src={handleVideoLink(videos[0].videos[0].filename)}
                        class="video-tutorial"
                        onPlay={handleplay}
                        onPause={handlepause}
                        playerRef={video}
                        // onTimeUpdate={handleTime}
                        controls
                      ></Reacthlsplayer>
                    </>
                  )}
                {!played &&
                  !vid &&
                  lastvideo[0] &&
                  videos[0] &&
                  videos.map((item) => {
                    return item.videos.find((res) => {
                      if (res.id == lastvideo[0].id) {
                        return res;
                      } else {
                        return null;
                      }
                    });
                  })[1] && (
                    <>
                      <Reacthlsplayer
                        id="video"
                        width="382"
                        height="192"
                        src={handleVideoLink(
                          videos.map((item) => {
                            return item.videos.find((res) => {
                              if (res.id == lastvideo[0].id) {
                                return res;
                              } else {
                                return null;
                              }
                            });
                          })[1].filename
                        )}
                        class="video-tutorial"
                        onPlay={handleplay}
                        onPause={handlepause}
                        playerRef={video}
                        // onTimeUpdate={handleTime}
                        controls
                      ></Reacthlsplayer>
                    </>
                  )}
              </div>
              {!played &&
                !vid &&
                lastvideo[0] &&
                videos[0].videos.find((res) => {
                  if (res.id == lastvideo[0].id) {
                    return res;
                  }
                }) &&
                videos[0].videos.find((res) => {
                  if (res.id == lastvideo[0].id) {
                    return res;
                  }
                }).pdffilename && (
                  <div class="video-content">
                    <Document
                      file={`${fileURL}/${
                        videos[0].videos.find((res) => {
                          if (res.id == lastvideo[0].id) {
                            return res;
                          }
                        }).pdffilename
                      }`}
                      onLoadSuccess={onDocumentLoadSuccess}
                    >
                      {handlePdf()}
                    </Document>
                  </div>
                )}
              {vid &&
                videos[0].videos.find((res) => {
                  if (res.filename == vid) {
                    return res;
                  }
                }) &&
                videos[0].videos.find((res) => {
                  if (res.filename == vid) {
                    return res;
                  }
                }).pdffilename && (
                  <Document
                    file={`${fileURL}/${
                      videos[0].videos.find((res) => {
                        if (res.filename == vid) {
                          return res;
                        }
                      }).pdffilename
                    }`}
                    onLoadSuccess={onDocumentLoadSuccess}
                  >
                    {handlePdf()}
                  </Document>
                )}{" "}
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="container-fluid">
          {loading && (
            <div className="formloader">
              <div className="row text-center">
                <div className="col-12">
                  <img src={formloader} alt="" height="100" />
                </div>
                <div className="col-12 text-white h4">Loading ...</div>
              </div>
            </div>
          )}
          <div className="row">
            <div class="col-12 tutorial-banner pb-0 pb-lg-3">
              <CourseTab videos={videos} />
            </div>
            <div class=" pt-lg-4 align-items-center tab-contents">
              <div class="text-center my-lg-5 my-3">
                <img
                  src={datanotfoundimage}
                  width="450px"
                  className="datanotfoundimage"
                />
                <h3 class="mt-lg-4 mt-3">
                  This course doesn't have any tutorial video at this moment.
                </h3>
              </div>
            </div>
          </div>
        </div>
      );
    }
  } else {
    return (
      <div class=" pt-lg-4 align-items-center tab-contents">
        {loading && (
          <div className="formloader">
            <div className="row text-center">
              <div className="col-12">
                <img src={formloader} alt="" height="100" />
              </div>
              <div className="col-12 text-white h4">Loading ...</div>
            </div>
          </div>
        )}
        <div class="text-center my-lg-5 my-3">
          <img
            src={datanotfoundimage}
            width="450px"
            className="datanotfoundimage"
          />
          <h3 class="mt-lg-4 mt-3">
            This course doesn't have any tutorial video at this moment.
          </h3>
        </div>
        <div class="px-4">
          <button
            className="btn btn-blue"
            onClick={() => navigate(`/course/${cname}`)}
          >
            Back
          </button>
        </div>
      </div>
    );
  }
}
