import React, { useState, useEffect } from 'react';
import StudentListService from '../../services/Student-List-Service.js';
import { useNavigate, Link } from 'react-router-dom';

const StudentList = () => {
    const [Students, setStudents] = useState([]);
    const navigate = useNavigate()
    useEffect(() => {
        fetchStudents();
    }, []); // Fetch Students when the component mounts

    const fetchStudents = async () => {
        try {
            StudentListService.getAllStudent().then(res => {
                setStudents(res.data)
            }).catch(err => console.log(err))

        } catch (error) {
            console.error('Error fetching Students:', error.message);
        }
    };

    const editStudent = (id) => {
        // Implement your edit logic here
        navigate(`/admin/update-Student/${id}`)
    };

    const deleteStudent = async (id) => {
        StudentListService.deleteStudentByID(id).then(res => {
            if (res.status === 200) {
                alert("Data deleted successfully");
                fetchStudents()
            } else {
                alert("something wen wrong")
            }
        }).catch(error => alert(error))
    };

    return (
        <div className="container mt-4">
            <div className="d-flex justify-content-between align-items-center">
                <h2>Student List</h2>
                <Link to={`/admin/create-Student`}>
                    <button className="btn btn-md btn-success">Add Student</button>
                </Link>

            </div>
            <table className="table">
                <thead>
                    <tr>
                        <th>Student Name</th>
                        <th>studentemail</th>
                        <th>Birthdate</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {Students.map((Student) => (
                        <tr key={Student.id}>
                            <td>{Student.studentname}</td>
                            <td>{Student.studentemail}</td>
                            <td>{Student.birthdate.split('T')[0]}</td>
                            <td>
                                <Link to={`/admin/studentdetails?id=${Student.id}`}>
                                    <button data-toggle="tooltip" data-placement="top" title='view' className="btn me-1" >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye-fill" viewBox="0 0 16 16">
                                            <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0" />
                                            <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8m8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7" />
                                        </svg></button>
                                </Link>
                                <Link to={`/admin/update-Student/${Student.id}`}>
                                    <button className="btn btn-primary">
                                        Edit
                                    </button></Link>

                                <button className="btn btn-danger ms-2" onClick={() => deleteStudent(Student.id)}>
                                    Delete
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default StudentList;
